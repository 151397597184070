.hero {
	position relative
	box-shadow $default-box-shadow

	img{
		width: 100%;
		@media screen and (max-width: 1199px) {
			min-height: 650px;
		}

		@media screen and (max-width: 991px) {
			min-height: 450px;
		}

		@media screen and (max-width: 575px) {
			min-height: 600px;
		}
	}

	&.small {
		background-image url('../img/hero-small-bg.png')
		height 380px
		box-shadow none

		@media screen and (max-width: 1199px) {
			height 300px
		}

		@media screen and (max-width: 768px) {
			height 200px
		}

		@media screen and (max-width: 575px) {
			height 150px
		}
	}

	header {
		.navbar {
			background-color transparent
		}
	}

	.hero-content {
        .slick-dotted {
            &.slick-slider {
                margin-bottom 0
            }

            .slick-dots {
                bottom 15px

                li {
                    width 13px
                    height 13px

                    button {
                        width 13px
                        height 13px
                    }
                }
            }
        }

        .hero-filter {
            position absolute
            top 50%
            left 50%
            transform translate(-50%, -50%)

            .index-video-gallery {
                position absolute
                bottom 0
            }

			@media screen and (max-width: 991px) {
				top 81%
			}

			@media screen and (max-width: 575px) {
				top 87%
			}
        }

        .slide {
            position relative

            &:after {
                content ''
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                background-color rgba(0,0,0,.222) 
                z-index 1
            }

            .container {
                position absolute
                top 40%
                width 100%
                left 50%
                transform translate(-50%, -50%)
                z-index 2
            }
        }

/* 		@media all and (max-width: 768px) {
			margin-top 70px
			top 0
			position absolute
			transform none
		} */

		h1 {
			line-height 1
			margin-top 40px
			margin-bottom 35px

			@media all and (min-width: 992px) and (max-width: 1199px) {
				font-size 56px
			}

			@media screen and (max-width: 991px) {
				margin-top 20px
				margin-bottom 20px
			}

			@media all and (max-width: 991px) {
				font-size 38px
				line-height 1.2
			}
		}

		h3 {
			font-weight 300
			line-height 1.2

			@media all and (max-width: 1199px) {
				font-size 18px
			}
		}

		.btn {
			@media screen and (max-width: 991px) {
				margin-top 0
				width 100%
				max-width 300px
			}

			@media all and (max-width: 425px) {
				max-width 100%
			}
		}
	}
}
