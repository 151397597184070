/**
 * Slick theme change here
 */
.slick-loading .slick-list {
	background $white url('../img/ajax-loader.gif') center center no-repeat
}

/* Icons */
@font-face {
	font-family 'slick'
	font-weight normal
	font-style normal
	src url('../fonts/slick/slick.eot')
	src url('../fonts/slick/slick.eot?#iefix') format('embedded-opentype'),
		url('../fonts/slick/slick.woff') format('woff'),
		url('../fonts/slick/slick.ttf') format('truetype'),
		url('../fonts/slick/slick.svg#slick') format('svg')
}
/* Arrows */
.slick-prev,
.slick-next {
	font-size 0
	line-height 0
	display block
	position absolute
	top 0
	bottom 0
	width 70px
	padding 0
	cursor pointer
	border 0
	outline 0
	background transparent
	z-index 1

	&:hover {

		&:before {
			opacity 1
		}
	}

	&:before {
		content ''
		position absolute
		height 200px
		top 0
		width 53px
		background-image url('../img/svg/arrow-right.svg')
		background-size 13px 22px
		background-position center
		background-repeat no-repeat
		transition opacity 0.3s ease-in-out
		pointer-events none
		cursor pointer

		@media screen and (max-width: 768px) {
			height 135px
			width 40px
			opacity 1
			pointer-events all
		}
	}
}

.slick-prev {
	left 0

	&:before {
		left 0
		transform rotate(180deg)
	}
}

.slick-next {
	right 0

	&:before {
		right 0
	}
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom 30px
}

.slick-dots {
	position absolute
	left 0
	right 0
	bottom -10px
	display block
	padding 0
	margin 0
	list-style none
	text-align center
	line-height 0.1px

	li {
		position relative
		display inline-block
		width 9px
		height 9px
		margin 0 5px
		padding 0
		cursor pointer

		button {
			font-size 0
			line-height 0
			display block
			width 9px
			height 9px
			padding 0
			cursor pointer
			color transparent
			outline 0
			background transparent
			border 1px solid $grey
			border-radius 50%

			&:hover,
			&:focus {
				outline 0
			}
		}

		&.slick-active button {
			border-color $primary
			background-color $primary
		}
	}
}

// prevent slide flickering when going from last to first slide
.slick-track, .slick-list {
	perspective 2000px
	backface-visibility hidden
}