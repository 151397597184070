.dream-homes {

	.house-cards-wrapper {
		display flex
		justify-content space-between

		@media screen and (max-width: 575px) {
			width calc(100% + 30px)
			margin-left: -15px
		}

		.house-card:nth-child(2) {

			@media screen and (max-width: 1199px) {
				margin 0 20px 50px
			}

			@media screen and (max-width: 991px) {
				margin 0 15px 25px
			}
		}
	}

	.btn {
		margin-top 10px
		padding 16px 20px
	}

	.youtube-iframe {
		background-color #c6c6c6
		background-position center -60px
		background-repeat no-repeat
		background-size cover
		height 0
		margin 50px 0 0
		max-width 100%
		overflow hidden
		padding-bottom 56.23%
		position relative

		@media screen and (max-width: 575px) {
			padding 0 0 51%
			max-width calc(100% - 30px)
			margin 50px 15px 0 15px
		}

		iframe {
			background transparent
			height 100%
			left 0
			margin 0 !important
			position absolute
			top 0
			width 100%
			z-index 100
		}
	}
}
