header {
	.navbar {
		background-color $dark-grey
		padding 15px 0
		transition background-color .3s ease-out

		&.main {
			background-color transparent
			transition background-color .3s ease-out
		}

		&.scrolled, &.dropdown-open, &.menu-open {
			background-color $dark-grey
			transition background-color .3s ease-out
		}

		.navbar-brand {
			padding 0
			margin 0

			img {
				width 128px
				height 32px
			}
		}

		.hamburger {
			display none
			padding 0 0 0 15px
			line-height .1px

			@media screen and (max-width: 991px) {
				display inline-block
			}

			.hamburger-box {
				height 40px
			}

			.hamburger-inner {
				top 11px
			}

			.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
				background-color $secondary
				height 2px
			}

			&.is-active .hamburger-inner {
				background-color transparent
			}

			&:hover {
				opacity 1
			}
		}

		.search-icon {
			display none
			padding 4px 15px 0 0
			cursor pointer

			@media screen and (max-width: 991px) {
				display block
			}

			svg {
				width 24px
				height 24px
			}
		}

		.menu-wrapper {
			display flex
			flex-basis auto
			flex-grow 1
			align-items center

			@media screen and (max-width: 991px) {
				display block
				position fixed
				top 64px
				left 0
				right 0
				bottom 0
				background $dark-grey
				transform translateY(100%)
				transition transform .3s ease-out
				padding-top 20px
				overflow auto
				text-align center

				&.is-active {
					transform translateY(0)
				}
			}
		}

		.navbar-nav {
			&.navbar-side {
				@media screen and (min-width: 992px) {
					transform translateX(17.5px)
				}
			}

			.nav-item {
				&#messageNotifications {
					position relative
				}

				.nav-link {
					font-family $montserrat
					font-weight 300
					font-size 16px
					color $white
					padding 11px 10px
					transition color .5s ease

					&:hover {
						color $secondary
					}

					&.active {
						color $secondary
					}

					@media all and (min-width: 992px) and (max-width: 1199px) {
						font-size 15px
						padding 11px 7px
					}

					@media screen and (max-width: 991px) {
						padding 0 15px 15px
					}
				}
			}
		}

		.personal-nav {
			padding-left 27px
			padding-right 7px
			margin-left 24.5px
			position relative

			@media all and (min-width: 992px) and (max-width: 1199px) {
				padding-left 8px
				padding-right 7px
				margin-left 28px
			}

			@media screen and (max-width: 991px) {
				flex-direction column
				justify-content space-between
				padding 0 2.5px 10px
				margin-left 0
				max-width 320px
				margin-left auto
				margin-right auto
			}

			&:before {
				content ''
				position absolute
				top 50%
				left 0
				height 20px
				transform translateY(-50%)
				background-color $white
				width 1px
				opacity .5

				@media screen and (max-width: 991px) {
					display none
				}
			}

			.nav-item {
				.nav-link {
					display flex
					align-items center
					justify-content center
					padding 10px 12.5px
					cursor pointer

					svg {
						stroke $white
						fill transparent
						transition stroke .25s ease, fill .5s ease
					}

					&:hover {
						svg {
							stroke $secondary
						}
					}

					&.active-notification, &.active {
						svg {
							stroke $secondary
							fill $secondary

							.inline {
								stroke $black
							}
						}
					}

					img {
						height 20px
					}
				}
			}
		}

		.profile-item-wrapper {
			padding 8px 0 8px 20px
			cursor pointer

			@media screen and (max-width: 991px) {
				display none
			}
		}

		.profile-item {
			background-color $secondary
			border-radius 50%
			color $black
			font-family $montserrat
			font-weight 300
			font-size 16px
			line-height 30px
			position relative
			width 30px
			height 30px
			text-align center
			padding 0
			margin 0
			display block

			@media screen and (max-width: 991px) {
				margin-left 15px
				line-height 40px
				width 40px
				height 40px
				margin-left auto
				margin-right auto
			}
		}

		.dropdown-menu {
			margin-top 16px
			border none
			background-color $dark-grey
			border-radius 0
			padding 0 0 4px
			box-shadow $default-box-shadow
			transform translateX(15px)
			transition opacity .3s ease-out
			display none

			&.show {
				display block
				background-color transparent
			}

			&.dropdown-menu-right {
				left auto !important
			}

			@media screen and (max-width: 991px) {
				box-shadow none
				position relative
				top 0
				left 0
				transform none
				text-align center
				float none
				opacity 1
				pointer-events all
				display none

				&.show {
					display block
				}
			}

			.dropdown-item {
				font-family $montserrat
				font-weight 300
				font-size 16px
				color $white
				transition color .5s ease
				position relative
				padding 6px 25px
				white-space normal
				width 300px
				background-color $dark-grey

				@media screen and (max-width: 991px) {
					padding 0 15px 15px
				}

				&:hover, &:focus, &:active, &.active {
					background-color $dark-grey
				}

				&:not(:last-child):after {
					content ''
					position absolute
					bottom 0
					left 20px
					right 20px
					height 1px
					background-color $white
					opacity .5

					@media screen and (max-width: 991px) {
						display none
					}
				}

				small {
					font-size 13px
				}

				.time {
					font-size 11px
					padding-top 4px

					i {
						padding-right 3px
					}
				}
			}

			div.dropdown-item {
				cursor default
			}

			a.dropdown-item {
				&:hover {
					color $secondary
				}
			}
		}

		.profile-dropdown {
			.dropdown-menu {
				margin-top 15px

				@media screen and (max-width: 991px) {
					display block
				}

				.dropdown-item {
					width auto
				}
			}
		}

		.search-layer {
			display none

			@media screen and (max-width: 991px) {
				display block
				position fixed
				top 64px
				left 0
				right 0
				bottom 0
				background $dark-grey
				transform translateY(100%)
				transition transform .3s ease-out
				color $white
				padding-top 20px
				padding-bottom 20px
				overflow auto

				&.is-active {
					transform translateY(0)
				}

				dark-bg()
			}
		}
	}
}

.notification-badge {
	position absolute
	top 0
	right 0
	font-size 10px
	color #fff
	background red
	border-radius 30px
	width 15px
	height 15px
	text-align center

	@media all and (max-width: 991px) {
		transform translateX(10px)
	}
}

.mark-notification-seen {
	position absolute
	right 5px
	top -4px
	width 6px
	cursor pointer
	z-index 50000
}
