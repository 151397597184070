.lawyer-card {
	position relative
	width 255px
	text-align center

	@media screen and (max-width: 991px) {
		width 200px
	}
		
	@media screen and (max-width: 768px) {
		width 130px
	}

	@media screen and (max-width: 575px) and (min-width: 420px) {
		margin 0 20px
	}

	//box-shadow hack for lawyer profile image
	&:before {
		content ''
		position absolute
		top 137px
		left 0
		right 0
		height calc(297px - 137px)
		box-shadow $default-box-shadow

		@media screen and (max-width: 991px) {
			top 108px
			height calc(234px - 108px)
		}
			
		@media screen and (max-width: 768px) {
			top 70px
			height calc(152px - 70px)
		}
	}

	img {
		width 100%
		height 297px
		object-position bottom
		object-fit contain
		font-family 'object-position bottom, object-fit contain'
		position relative
		z-index 1

		@media screen and (max-width: 991px) {
			height 234px
		}
		
		@media screen and (max-width: 768px) {
			height 152px
		}
	}

	.lawyer-texts {
		margin-top 30px
		display flex
		flex-direction column
		align-items center
		justify-content center

		@media screen and (max-width: 768px) {
			margin-top 20px
		}

		.lawyer-name {
			font-weight bold
			line-height 1.1em
			position relative
			height 55px
			max-width 160px

			@media screen and (max-width: 991px) {
				max-width none
			}

			@media screen and (max-width: 768px) {
				font-size 12px
				height 40px
			}
		}

		.lawyer-office {
			position relative
			min-height 31px
			max-width 160px
			
			@media screen and (max-width: 991px) {
				max-width none
			}

			@media screen and (max-width: 768px) {
				font-size 9px
			}
		}

		.lawyer-location {
			position relative
			max-width 160px

			@media screen and (max-width: 991px) {
				max-width none
			}

			@media screen and (max-width: 768px) {
				font-size 9px
			}
		}
	}

	&.small {
		width 160px

		&:before {
			top 86px
			height calc(186px - 86px)

			@media screen and (max-width: 991px) {
				top 86px
				height calc(186px - 86px)
			}

			@media screen and (max-width: 768px) {
				top 86px
				height calc(186px - 86px)
			}
		}

		img {
			height 186px

			@media screen and (max-width: 991px) {
				height 186px
			}

			@media screen and (max-width: 768px) {
				height 186px
			}
		}
	}
}