@font-face {
	font-family 'Montserrat'
	src url('../fonts/Montserrat-Regular.eot');
	src url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Regular.woff2') format('woff2'),
		url('../fonts/Montserrat-Regular.woff') format('woff'),
		url('../fonts/Montserrat-Regular.ttf') format('truetype'),
		url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg')
	font-weight normal
	font-style normal
}

@font-face {
	font-family 'Montserrat'
	src url('../fonts/Montserrat-Light.eot')
	src url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Light.woff2') format('woff2'),
		url('../fonts/Montserrat-Light.woff') format('woff'),
		url('../fonts/Montserrat-Light.ttf') format('truetype'),
		url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg')
	font-weight 300
	font-style normal
}


@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-LightItalic.eot');
	src: url('../fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-LightItalic.woff2') format('woff2'),
		url('../fonts/Montserrat-LightItalic.woff') format('woff'),
		url('../fonts/Montserrat-LightItalic.ttf') format('truetype'),
		url('../fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family 'Montserrat'
	src url('../fonts/Montserrat-Bold.eot');
	src url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Montserrat-Bold.woff2') format('woff2'),
		url('../fonts/Montserrat-Bold.woff') format('woff'),
		url('../fonts/Montserrat-Bold.ttf') format('truetype'),
		url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg')
	font-weight bold
	font-style normal
}

h1 {
	font-family $montserrat
	font-weight bold
	font-size 76px
	line-height 1
	letter-spacing -1px
	margin-bottom 0

	@media screen and (max-width: 768px) {
		font-size 48px
	}
}

h2 {
	font-family $montserrat
	font-weight bold
	font-size 48px
	line-height 1.2
	letter-spacing -1.35px
	margin-bottom 0

	@media screen and (max-width: 768px) {
		font-size 24px
	}
}

h3 {
	font-family $montserrat
	font-weight bold
	font-size 24px
	line-height 1.2
	margin-bottom 0

	@media screen and (max-width: 768px) {
		font-size 17px
	}
}

h4 {
	font-family $montserrat
	font-weight 300
	font-size 18px
	line-height 1.2
	margin-bottom 0

	@media screen and (max-width: 768px) {
		font-size 12px
	}
}

h5{
	&.error{
		color $error-color
	}
}

p {
	font-family $montserrat
	font-weight 300
	font-size 16px
	line-height 1.5
	margin-bottom 0

	@media screen and (max-width: 575px) {
		font-size 15px
	}
}

i {
	font-family $montserrat
	font-weight 300
	font-style italic
}

small {
	display block
	font-family $montserrat
	font-weight 300
	font-size 14px
	line-height 1.1
	margin-bottom 0

	@media screen and (max-width: 768px) {
		font-size 9px
	}
}

.tag {
	font-family $montserrat
	font-weight 300
	font-size 14px
	line-height 25px
	padding 0 20px
	background-color $secondary
	display inline-block
	margin-bottom 0

	@media screen and (max-width: 768px) {
		font-size 9px
		line-height 17px
		padding 0 14px
	}
}

a {
	color currentColor
	text-decoration none

	&:hover, &:focus {
		color currentColor
		text-decoration none
	}
}

strong, b {
	font-weight bold
}

.font-weight-normal {
	font-weight normal
}

.font-weight-light {
	font-weight 300
}

.font-weight-bold {
	font-weight bold
}

.font-size-10 {
	font-size 10px
}

.font-size-14 {
	font-size 14px
}

.text-underline {
	text-decoration underline
}
