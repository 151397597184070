#app {
    .dimmer {
        position absolute
        background-color #ffffffdd
        top 0
        left 0
        width 100%
        height 100%
    }

    height calc(100vh - 76px)
    overflow hidden

    @media screen and (max-width: 991px) {
        height calc(100vh - 70px)
    }

    .property-search {
        padding-top 24px
        padding-bottom 0
        height 100%

        .filterList {
            display flex
            flex unset
            align-items center
            justify-content flex-start
            flex-wrap wrap
            margin-bottom 10px

            .form-group, label {
                @media screen and (max-width: 1649px) {
                    margin-bottom 17px
                }

                @media screen and (max-width: 1199px) {
                    margin-bottom 0
                }
            }

            .form-check {
                label {
                    font-weight 300
                    margin-bottom 5px

                    @media screen and (max-width: 1649px) {
                        margin-bottom 17px
                    }
                }
            }
        }

        .filterListItem {
            position relative
            border 1px solid $primary
            margin 4px 8px 4px 0
            padding 4px
            cursor default
            user-select none
            display flex
            justify-content space-between

            .clear-button.nojs {
                position relative
                margin-left 4px
            }

            &:hover {
                border 1px solid $primary-hover
            }
        }

        .filterContainer {
            .filterDropdown {
                position absolute
                z-index 100
                background-color white
                box-shadow $default-box-shadow

                @media screen and (max-width: 991px) {
                    width calc(100vw - 30px)
                    left 0
                }

                &.multiSelect {
                    height auto
                    padding 10px
                    width 100%
                }

                &.additionalFilters {
                    left 0
                    display flex
                    width 1097px
                    padding 12px
                    overflow auto
                    max-height 60vh

                    .budapestMap {
                        margin-top 12px

                        .district {
                            fill white
                            stroke $primary
                            stroke-width 2px
                            cursor pointer

                            &.selected {
                                fill $secondary
                            }
                        }

                        .districtNumber {
                            fill $primary
                        }

                        .danube {
                            fill $primary
                        }
                    }

                    @media screen and (max-width: 1649px) {
                        width 735px
                    }

                    @media screen and (max-width: 1199px) {
                        width 100%
                        max-height 60vh
                    }

                    @media screen and (max-width: 991px) {
                        width 100%
                        flex-wrap wrap
                    }

                    .xButton {
                        padding-top 3px
                    }

                    .column {
                        width 33%
                        margin-right 20px

                        @media screen and (max-width: 991px) {
                            width 100%
                        }

                        label {
                            font-weight 700
                        }

                        .form-check {
                            label {
                                font-weight 300
                            }
                        }

                        .form-group {
                            margin-bottom 16px
                        }

                        .autoCompleteFilter {
                            max-width 400px
                            position relative

                            input.form-control {
                                margin-bottom 6px
                            }

                            .selections {
                                display flex
                                flex-wrap wrap

                                .selection {
                                    background-color $light-grey
                                    padding 5px
                                    margin 5px
                                    font-size 15px
                                    border-radius 4px
                                    cursor pointer
                                }
                            }

                            .results {
                                max-height 280px
                                overflow-y auto

                                div {
                                    cursor pointer
                                }
                            }
                        }
                    }

                    .rangeFilter {
                        display flex
                        flex-direction row
                        flex-wrap nowrap
                        height auto
                        width auto
                        justify-content space-between

                        @media screen and (max-width: 991px) {
                            width 100%
                            max-width none
                        }

                        .column {
                            width 50%
                            display flex
                            flex-direction column
                            align-items center

                            @media screen and (max-width: 991px) {
                                width 100%
                                flex-wrap wrap
                            }

                            input {
                                width 100%
                            }

                            ul {
                                width 100%
                                display flex
                                flex-direction column
                                align-items flex-end
                                margin 6px 0 0

                                li {
                                    cursor pointer
                                }
                            }
                        }
                    }

                    .typeFilter {
                        margin-top 6px

                        .form-check {
                            margin-bottom 0

                            .form-check-label {
                                margin-bottom 0
                            }
                        }

                        .typesContainer {
                            flex-wrap wrap
                            box-sizing content-box

                            .childType {
                                margin-left 16px
                            }
                        }
                    }
                }
            }
        }

        label.canToggle {
            cursor pointer
            user-select none

            &.expanded {
                color $primary
            }

            div {
                width 30px
                position relative

                i {
                    display block
                    background-image url('../img/svg/select-arrow.svg')
                    background-position center center
                    background-repeat no-repeat
                    position absolute
                    top 0
                    bottom 0
                    width 16px
                    margin auto

                    &.expanded {
                        transform rotate(180deg)
                        background-image url('../img/svg/select-arrow-hover.svg')
                    }
                }
            }
        }

        #additionalFiltersButton {
            margin-left 16px

            @media all and (min-width: 1200px) and (max-width: 1319px) {
                padding 0
                font-size 17px
            }

            @media all and (min-width: 768px) and (max-width: 1199px) {
                float right
                margin 0
                padding-right 0
            }

            @media all and (max-width: 767px) {
                padding 0
                font-size 17px
            }
        }

        @media screen and (max-width: 768px) {
            padding-top 10px
        }

        .container-fluid {
            height 100%

            .row {
                height 100%
                flex-wrap nowrap
                flex-direction column
            }
        }

        .filters-wrapper {
            display flex
            margin-bottom 10px
            flex 0 0 auto

            @media screen and (max-width: 1649px) {
                margin-bottom 0
            }

            @media screen and (max-width: 1199px) {
                flex-wrap wrap
            }

            .left-side, .right-side {
                position relative
                display flex
                justify-content space-between

                label, .form-group, .btn {
                    margin-bottom 0
                }

                label {
                    font-weight 300
                }
            }

            .left-side {
                position relative
                align-items center
                padding-right 15px

                @media all and (min-width: 768px) and (max-width: 1199px) {
                    width 100%
                    padding 0
                    display block
                }

                @media all and (max-width: 767px) {
                    display block
                    width 100%
                    padding 0
                    padding-top 15px
                }

                .w-house-card {
                    position relative
                    width 350px
                    margin-bottom 0

                    @media screen and (max-width: 1649px) {
                        margin-bottom 20px
                        margin-right 15px
                    }

                    @media all and (min-width: 1320px) and (max-width: 1393px) {
                        width 275px
                        margin-right 7.5px
                    }

                    @media all and (min-width: 1200px) and (max-width: 1319px) {
                        width 225px
                    }

                    @media all and (min-width: 768px) and (max-width: 1199px) {
                        display inline-block
                    }

                    @media screen and (max-width: 1199px) {
                        width 292px
                    }

                    @media screen and (max-width: 991px) {
                        margin-right 0
                        margin-bottom 5px
                    }

                    @media all and (max-width: 767px) {
                        width 100%
                        margin-bottom 30px
                    }
                }

                .btn {
                    @media screen and (max-width: 1649px) {
                        margin-bottom 20px
                        margin-right 15px
                    }

                    @media screen and (max-width: 991px) {
                        &:last-child {
                            margin-right 0
                        }
                    }

                    @media all and (max-width: 767px) {
                        margin-left 0 !important
                    }
                }
            }

            .right-side {
                align-items center
                flex-grow 1

                @media screen and (max-width: 1649px) {
                    align-items flex-end
                }

                @media screen and (max-width: 1199px) {
                    width 100%
                }

                .filters {
                    display flex
                    flex-wrap wrap
                    align-items flex-end

                    > .form-group {
                        margin-right 15px

                        @media all and (max-width: 767px) {
                            margin-right 10px
                        }
                    }
                }

                .form-group, label {
                    @media screen and (max-width: 1649px) {
                        margin-bottom 17px
                    }

                    @media screen and (max-width: 1199px) {
                        margin-bottom 0
                    }
                }

                .form-check {
                    label {
                        margin-bottom 5px

                        @media screen and (max-width: 1649px) {
                            margin-bottom 17px
                        }
                    }
                }

                .map-toggle {
                    display flex
                    align-items center
                    margin-bottom 0

                    @media screen and (max-width: 1649px) {
                        margin-bottom 8px
                    }

                    @media all and (max-width: 767px) {
                        position fixed
                        bottom 5px
                        left 20px
                        z-index 999
                        background white
                        border-radius 4px
                        padding 2.5px 4px 2.5px 7px
                        margin-bottom 0
                    }

                    label:first-child {
                        font-weight normal
                        margin-right 10px
                    }
                }
            }
        }

        .house-list {
            display flex
            flex-wrap nowrap
            flex 1
            padding-right 0
            padding-left 0

            @media screen and (max-width: 991px) {
                padding 0
            }

            @media screen and (max-width: 767px) {
                padding-top 60px
            }

            .house-cards-wrapper {
                display flex
                flex-wrap wrap
                position relative
                width 1112px
                margin-bottom -20px
                overflow auto
                height 100%

                @media screen and (max-width: 1649px) {
                    width 748px
                }

                @media screen and (max-width: 1199px) {
                    width 634px
                }

                @media screen and (max-width: 991px) {
                    width auto
                    justify-content space-between
                    padding 0 15px
                }

                & > div {
                    @media screen and (max-width: 991px) {
                        width 100%
                    }
                }

                .house-card {
                    display inline-block

                    &:nth-child(3n+1), &:nth-child(3n+2) {
                        margin-right 15px
                    }

                    @media screen and (max-width: 1649px) {
                        &:nth-child(3n+1), &:nth-child(3n+2) {
                            margin-right initial
                        }

                        &:nth-child(2n+1) {
                            margin-right 15px
                        }
                    }

                    @media screen and (max-width: 991px) {
                        width calc(50% - 15px) !important
                    }

                    @media screen and (max-width: 575px) {
                        width 237px !important
                        margin-left 7.5px !important
                        margin-right 7.5px !important
                    }

                    @media screen and (max-width: 533px) {
                        margin-left auto !important
                        margin-right auto !important
                        width 100% !important
                    }
                }

                .pagination {
                    font-size 32px
                    padding 6px 0
                    width 100%
                    display flex
                    justify-content center
                    align-items center
                    position relative
                    align-self flex-end

                    i {
                        cursor pointer
                        color $primary
                    }

                    .right {
                        margin-left 100px
                    }

                    .left {
                        margin-right 100px
                    }

                    .disabled {
                        color lightgrey
                    }
                }
            }

            &.hide-map {
                .house-cards-wrapper {
                    width 100%

                    .house-card {
                        &:nth-child(3n+1), &:nth-child(3n+2), &:nth-child(3n+3), &:nth-child(3n+4) {
                            margin-right 15px
                        }
                    }
                }
            }
        }

        .properties-map {
            display flex
            flex-grow 1

            @media screen and (max-width: 991px) {
                position absolute
                left 0
                right 0
                top 0
                bottom 0
                z-index 1
            }

            .g-map {
                position relative
                width 100%
                min-height 350px
                height calc(100vh - 104px) // header + 30px padding
            }

            .marker {
                width 18px
                height 21px
                transform translate(-50%, -100%)
                cursor pointer
                transition all ease .2s

                &.active {
                    transition all ease .2s
                    transform-origin 0 0
                    transform scale(1.5) translate(-50%, -100%)
                }
            }
        }
    }
}

#app + footer {
    display none
}

.property-search + footer {
    display none
}

.xButton {
    display flex
    align-items flex-end

    &.icon {
        color $primary
    }

    svg {
        stroke $grey
        transition all .4s ease-out
        margin-right 10px
        transform translateY(-3px)

        @media screen and (max-width: 1649px) {
            margin-bottom 10px
        }
    }

    .saveSearch {
        width 25px
        height 25px
        background-image url('../img/svg/save_search.svg')
        background-size contain
        background-position center
        background-repeat no-repeat
        color $primary
        margin-right 10px

        @media screen and (max-width: 768px) {
            top 15px
        }
    }

    &:hover {
        svg {
            stroke $primary
        }
    }
}

.btn.borderless {
    background-color white
    color $primary-hover
    text-decoration underline
}

.SumoSelect {
    margin-left 0 !important
}

.search-input-placeholder {
    position absolute
    font-weight 300
}

.template__search {
    .mobile__search {
        display none

        .btn {
            text-decoration none
            padding 15px
            margin 0
            width 100%
            text-align left
        }
    }

    @media all and (max-width: 767px) {
        .property-search {
            padding-top 50px !important
        }

        #app .property-search .filterList {
            margin-bottom 0
            display none
        }

        #app .property-search .filters-wrapper {
            display none
        }

        .switch {
            position relative
            display inline-block
            width 55px
            height 30px
        }

        .slider:before {
            height 30px
            width 30px
        }

        .slider:after {
            top 8px
            left 7.5px
            width 15px
            height 15px
        }

        .mobile__search {
            display block
            position fixed
            top 70px
            z-index 99
            width 100%
            left 0
            right 0
            padding 0

            .btn i {
                transform rotate(0)
                transition transform .3s ease
            }

            &.active {
                .btn i {
                    transform rotate(180deg)
                }
            }
        }
    }
}
