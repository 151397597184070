.pdf-options {
	display flex
	align-items baseline
	justify-content flex-end
	margin-bottom 20px

	.svg-wrapper {
		display block
		position relative
		width 22px
		height 22px
		cursor pointer

		&:not(:last-child) {
			margin-right 20px
		}

		svg {
			stroke $primary
		}

		&:hover {

			svg {
				stroke $primary-hover
			}
		}
	}
}
	
.pdf-wrapper {
	background-color $input-default-border
	padding 20px
	display block
	position relative
	margin-bottom 20px

	@media screen and (max-width: 768px) {
		padding 0
	}

	.pdf-content {
		background-color $white
		display block
		position relative
		padding 40px
		
		@media screen and (max-width: 991px) {
			padding 30px 20px
		}

		@media screen and (max-width: 768px) {
			padding 30px 0
		}
		
		small {
			line-height 1.8em
		}

		.dotted {
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4px' height='30px'%3E%3Cline x1='1' x2='29' y1='29' y2='29' stroke='%23000000' stroke-width='1' stroke-linecap='round' stroke-dasharray='1, 3'/%3E%3C/svg%3E")
			background-repeat repeat-x
			background-position 0 -11px
			background-size inherit
			position relative
			text-align center
			display inline
			padding-left 30px
			padding-right 30px

			@media screen and (max-width: 768px) {
				background-position 0 -17px
			}

			&.extra-wide,
			&.wide {
				padding-left 50px
				padding-right 50px

				@media screen and (max-width: 768px) {
					padding-left 25px
					padding-right 25px
				}
			}
		}
	}
}

.deal-show {

	.pdf-wrapper {
		padding 65px
		
		@media screen and (max-width: 991px) {
			padding 20px
		}
	}
}

.blurred {
	user-select none
	-webkit-filter blur(5px)
	-moz-filter blur(5px)
	-o-filter blur(5px)
	-ms-filter blur(5px)
	filter url(#blur)
	filter blur(5px)
}