.cookie-popup{
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	background: #bedc1e;
	z-index: 10000;
	text-align: center;
	padding: 20px;
	@media screen and (max-width: 768px) {
		padding: 20px 20px 50px 20px;
	}
	@media screen and (max-width: 991px) {
		height auto
	}
}

footer {
	background-image url('../img/footer-bg.png')
	background-size cover
	background-position center
	background-repeat no-repeat
	height 500px
	color white
	font-family $montserrat
	font-weight 300
	font-size 14px
	line-height 1em
	
	@media screen and (max-width: 991px) {
		height auto
	}
		
	@media screen and (max-width: 768px) {
		font-size 8px
	}

	.simple-icons{
		margin-top: -35px;
	}

	.footer-link {
		transition color .5s ease

		&:hover {
			color $secondary
		}
	}

	.footer-wrapper {
		display flex
		justify-content space-between
		margin 100px 0

		@media screen and (max-width: 991px) {
			flex-direction row
			flex-wrap wrap
			margin 50px 0
		}

		.footer-column {
			display flex
			flex-direction column

			&:last-child {
				text-align right
			}

			@media screen and (max-width: 991px) {
				width 33%

				&:first-child {
					order -1
					width 40%
				}

				&:last-child {
					order -1
					width 60%
				}
			}

			.logo {
				position relative
				width 128px
				height 32px
				margin-bottom 30px

				@media screen and (max-width: 991px) {
					width 94px
					height 21px
				}
			}

			.footer-link {
				margin-bottom 20px

				@media screen and (max-width: 768px) {
					margin-bottom 13px
				}

				&.address {
					line-height 1.3em

					&:hover {
						color $white
					}
				}

				&.emphasized {
					font-weight bold
					margin-top 11px
					margin-bottom 35px

					@media screen and (max-width: 991px) {
						margin-bottom 25px
					}
				}
			}

			.social-wrapper {
				display flex
				margin-bottom 40px
				justify-content flex-end

				@media screen and (max-width: 991px) {
					margin-bottom 20px
				}

				.social {
					color $white
					position relative
					width 26px
					height 26px
					font-size 24px
					margin 5px
					text-align center
					transition color .5s ease
					
					@media screen and (max-width: 991px) {
						margin 0 3px
						font-size 20px
					}

					&:last-child {
						margin-right 0
					}

					&:hover {

						&:nth-child(1) {
							color $primary
						}

						&:nth-child(2) {
							color $primary-hover
						}

						&:nth-child(3) {
							color #5ab4aa
						}

						&:nth-child(4) {
							color $secondary-hover
						}
					}
				}
			}

			.small-house {
				position relative
				height 30px
				width 25px
				text-align right

				@media screen and (max-width: 991px) {
					height 27.5px
					width 23px
				}
			}
		}
	}

	.footer-copyright-wrapper {
		display flex
		justify-content space-between
		align-items baseline

		@media screen and (max-width: 991px) {
			flex-wrap wrap
			justify-content flex-start
			margin-bottom 20px
		}
		.copyright {

			@media screen and (max-width: 991px) {
				width 33%
			}
		}

		.footer-link {

			@media screen and (max-width: 991px) {
				width 33%
				margin-bottom 13px
				padding-right 70px
				line-height 1.2em

				&:last-item {
					margin-right auto !important
				}
			}

			@media screen and (max-width: 768px) {
				padding-right 80px
				line-height 1em
			}

			@media screen and (max-width: 575px) {
				padding-right 5px
			}
		}
	}
}

.footer-simple-pay {
	background $white

	.simple-icons {
		display flex
		align-items center
		justify-content center
		padding 10px 0

		@media screen and (max-width: 400px) {
			flex-direction column

			img:first-child {
				margin-bottom 10px
			}
		}
	}
}