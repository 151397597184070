* {
	outline 0 !important
}

body {
	font-family $montserrat
	font-size 16px
	font-weight 300
	color $black

	&.menu-open {
		@media screen and (max-width: 991px) {
			overflow hidden
		}
	}
}

.ml-15 {
	margin-left 15px
}

span {
	font-family $montserrat
	display inline-block
}

.alert {
	margin-bottom 25px

	&.alert-danger {
		background-color $error-color
		color $black
		border none
		border-radius 0
	}

	&.alert-success {
		background-color $secondary-hover
		color $white
		border none
		border-radius 0
	}
}

.header-fix {
	padding-top 76px
	background-color transparent

	@media screen and (max-width: 991px) {
		padding-top 70px
	}

	&.alert-on {
		@media screen and (min-width: 1200px) {
			/* padding-top 120px */
			padding-top 76px
		}
	}

	&.main {
		padding-top 0 !important
	}
}

.property-details-alert {
	background-color $secondary
	color $black

	@media screen and (min-width: 992px) {
		position fixed
		top 76px
		left 0
		right 0
		z-index 1029
	}

	@media screen and (max-width: 991px) {
		margin-top 70px
	}

	.missing-property-details {
		margin 12px 30px 12px 0
		position relative

		a {
			color $black
			text-decoration underline
			font-weight bold
			display inline-block
		}

		.edit-icon {
			display inline-block
			position relative
			width 20px
			height 20px
			background-image url('../img/svg/edit.svg')
			background-size contain
			background-position left
			background-repeat no-repeat
			margin-right 10px
			transform translateY(3px)
		}

		.close-icon {
			display inline-block
			position absolute
			top 0
			right -30px
			width 24px
			height 24px
			background-image url('../img/svg/close.svg')
			background-size contain
			background-position right
			background-repeat no-repeat
			cursor pointer
		}
	}
}

.section {
	padding-top 100px
	padding-bottom 100px

	@media screen and (max-width: 991px) {
		padding-top 50px
		padding-bottom 50px
	}
}

.header-fix + .section {
	padding-top 70px

	@media screen and (max-width: 991px) {
		padding-top 50px
	}
}

.section-title {
	margin-bottom 50px
	text-align center

	@media screen and (max-width: 991px) {
		margin-bottom 30px
	}
}

.main-title {
	margin-bottom 20px

	span {
		max-width calc(100% - 71px)
	}
}

.btn-flex-wrapper {
	display flex
	align-items flex-start
	justify-content center
	flex-wrap wrap

	@media screen and (max-width: 991px) {
		flex-wrap wrap
		flex-direction column-reverse
		align-items center
	}
}

.color-primary {
	color $primary
}

.color-secondary {
	color $secondary
}

.color-secondary-hover {
	color $secondary-hover
}

.color-white {
	color $white
}

.color-grey {
	color $grey
}

.h-0 {
	height 0 !important
	overflow hidden !important
}

.property-details-alert + .hero {
	.pre-search-box {
		margin-top 40px
	}
}

.header-fix.alert-on:not(.main) {
	+ .property-details-alert {
		margin-top 0
	}
}

.datetime.datetimemobile {
	display none
}

@media all and (max-width: 991px) {
	.no-padding-mobile {
		padding 0
	}

	.timetable-wrapper.lawyer {
		.slick-dots {
			bottom -30px
		}
	}

	.timetable {
		.chosen-infos {
			&.mt-3, &.my-3 {
				margin-top 4rem !important
			}
		}
	}

	.timetable.timetable .timetable-wrapper.lawyer .day-wrapper .hour p .taken-text {
		display none !important
	}

	.timetable .timetable-wrapper .day-wrapper .hour p {
		font-size 11px !important
		line-height normal !important

		span {
			display block
			line-height .4
		}
	}

	.dayofweek {
		display none !important
	}

	.datetime {
		display none
	}

	.datetime.datetimemobile {
		display block
		font-size 11px
		line-height normal

		span {
			display block
		}
	}
}

.loader{
	display: none;
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.4);
    z-index: 10000;
	img{
		position: fixed;
		left: 50%;
		top: 50%;
		width: 100px;
		height: 100px;
		margin: -50px 0 0 -50px;
		background: rgba(255, 255, 255, 0.4);
		border-radius: 100px;
	}
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.done-gdpr-alert-box-wrapper{
    background-color: #bedc1e!important
}
