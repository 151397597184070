.chat {
	padding-bottom 50px

	@media screen and (max-width: 991px) {
		padding-bottom 0px
	}
	&.section {
		padding-top 24px

		@media screen and (max-width: 991px) {
			padding-top 0
            position: relative;
            .col-12 {
                padding 0;
            }
            .input-box {
                height 50px
            }
		}

        .navigation {
            height: 45px;
            display: flex;
            align-items: flex-end;
            .btn {
                padding 0 25px
                font-size 16px;
                height auto;
                line-height 40px;
                margin-bottom 0;
                &:not(.active) {
                    background-color #ebebeb
                    color $grey
                }
                &.active {
                    height 100%;
                    font-weight bold;
                }
            }
        }
	}

	.mobile-nav-buttons {
		display none

		@media screen and (max-width: 991px) {
			display flex
			justify-content center
			margin-bottom 10px
		}

		.btn {

			&.active {
				background-color $primary-hover
			}
		}
	}

	.profile-img {
		display inline-block
		position relative
		width 40px
		height 40px
		background-size cover
		background-position center
		background-repeat no-repeat
		text-align center
		font-family $montserrat
		font-weight 300
		font-size 18px
		line-height 41px
		border none
		border-radius 50%
		background-color $secondary;
		color $black

		&.lawyer-img {
			background-color $thirdly
		}

		&.has-img {
			background-color $white
			color transparent !important
		}
	}

	.profile-images-wrapper {
		display inline-block
		position relative
		width 60px
		height 60px
		margin-right 10px

		.profile-img {
			line-height 38px
			border 2px solid $white
			position absolute
			left 0
			bottom 50%
			transform translateY(50%)
		}

		&.two-person {

			.profile-img {
				position absolute
				left 0
				bottom 0
				transform none

				&.lawyer-img {
					left auto
					bottom auto
					top 0
					right 0
				}
			}
		}
	}

	.chat-headline {
		display flex
		align-items center
		justify-content space-between

		.users {
			display flex
			align-items center
		}
	}

	.chat-wrapper {
		display flex
		flex 1
        box-shadow 0 30px 30px 0 rgba(0,0,0,.15);
		border 1px solid $input-default-border

		@media screen and (max-width: 991px) {
			flex-direction column
		}

		.left-side {
			width 66%
			display flex
			flex-direction column
            background-color $primary;

			@media screen and (max-width: 991px) {
				width 100%
				margin-bottom 50px
			}
		}

		.right-side {
			width calc(100% - 66%)
			border-left 1px solid $input-default-border

			@media screen and (max-width: 991px) {
				display block
				width 100%
				position: absolute;
				bottom: 0px;
				left: 0;
				right: 0;
				z-index 10
			}
		}
	}

	.chatbox {
		background-color #cdcdcd
		border 1px solid $input-default-border
		position relative
		height calc(100vh - 100px - 80px - 90px)
		padding 0 20px 20px
		overflow auto
		display block
		z-index 1
		width 100%
        margin-top 6px;

		& > div{
			min-height 100%;
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display block
		}

		@media screen and (max-width: 991px) {
			padding 0 15px 15px
			box-sizing: border-box
			height calc(100vh - 100px - 50px - 50px - 100px);
		}

		.message-row {
			position relative
			width 100%

			@media screen and (max-width: 768px) {
				padding-top 15px
			}

			&.status-text {
				align-self flex-end
				text-align right

				.message-wrapper {

					@media screen and (max-width: 768px) {
						margin-top 0
					}
				}
			}

			.profile-img {
				position absolute
				left 0

				@media screen and (max-width: 768px) {
					top 15px
				}
			}

			.info-icon {
				position absolute
				left 0
				width 40px
				height 40px
				background-image url('../img/svg/info.svg')
				background-size contain
				background-position center
				background-repeat no-repeat
                color $primary;

				@media screen and (max-width: 768px) {
					top 15px
				}
			}

			.message-wrapper {
				margin-left 50px;

				.message-box {
					background-color $white
					padding 12px 20px
					display inline-block
					font-family $montserrat
					font-weight 300
					font-size 16px
					color $black
					max-width 50%
                    word-break break-word;
                    border-radius 10px;
                    text-align initial;

					@media screen and (max-width: 768px) {
						padding 15px
						font-size 14px
						max-width 80%
					}

					@media screen and (max-width: 575px) {
						max-width none
					}

                    &.info {
                         background-color $primary
                         color $white
                         margin-bottom 0

                         a {
                             color $secondary
                             text-decoration underline

                             &:hover,
                             &:focus {
                                 color $secondary
                             }
                         }

                         .btn {
                             margin-top 15px
                             margin-bottom 0
                         }
                     }

					&.cu-message{
						background-color $white
						color $black
						margin-bottom 0

						a {
							color $secondary
							text-decoration underline

							&:hover,
							&:focus {
								color $secondary
							}
						}

						.btn {
							margin-top 15px
							margin-bottom 0
						}
					}
				}

				.message-date {
					font-weight 300
					font-size 12px
				}
			}
		}
	}

	.input-box {
		background-color $white
		border-top 0
		position relative
		height 55px
		display flex
		align-items center

		@media screen and (min-width: 992px) {
			border-right none
		}

		.input-wrapper {
			flex-grow 1
		}

		.form-control {
			border none
			line-height 25px
			height 55px
			padding 15px
			color $black
			background-color transparent

			@media screen and (max-width: 575px) {
				padding 15px 10px
			}

			&::placeholder {
				color $primary
			}

			&::-webkit-input-placeholder {
				color $primary
			}

			&::-moz-placeholder {
				color $primary
			}

			&:-ms-input-placeholder {
				color $primary
			}

			&:-moz-placeholder {
				color $primary
			}

			@keyframes autofill-bg-transparent {
				to {
					color $black
					background transparent
				}
			}

			&:-webkit-autofill {
				animation-name autofill-bg-transparent
				animation-fill-mode both
			}
		}

		.custom-input-label {
			padding 5px 10px
			cursor pointer

			@media screen and (max-width: 575px) {
				padding 5px
			}

			i {
				display inline-block
				position relative
				width 22px
				height 20px
				background-size contain
				background-position center
				background-repeat no-repeat
			}

			&.img-label {

				i {
					background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 342 306'%3E%3Crect fill='none' stroke='%23CCCCCC' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x='9' y='9' width='324' height='288'/%3E%3Cpolyline fill='none' stroke='%23CCCCCC' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='9,297 117,189 171,243 270,144 333,207'/%3E%3Cline fill='none' stroke='%23CCCCCC' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='171' y1='243' x2='117' y2='297'/%3E%3Ccircle fill='none' stroke='%23CCCCCC' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='94.5' cy='94.5' r='40.5'/%3E%3C/svg%3E%0A")
				}
			 }

			&.file-label {

				i {
					background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 342 314.9'%3E%3Cpath fill='none' stroke='%23CCCCCC' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M175,275.5l135.6-135.6c29.9-29.9,29.9-78.5,0-108.5l0,0c-29.9-29.9-78.5-29.9-108.5,0L25.8,207.7 c-22.5,22.5-22.5,58.9,0,81.3l0,0c22.5,22.5,58.9,22.5,81.3,0l158.2-158.2c15-15,15-39.3,0-54.2l0,0c-15-15-39.3-15-54.2,0 L93.6,194.1'/%3E%3C/svg%3E%0A")
				}
			}

			&:hover {

				&.img-label {

					i {
						background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 342 306'%3E%3Crect fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x='9' y='9' width='324' height='288'/%3E%3Cpolyline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='9,297 117,189 171,243 270,144 333,207'/%3E%3Cline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='171' y1='243' x2='117' y2='297'/%3E%3Ccircle fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='94.5' cy='94.5' r='40.5'/%3E%3C/svg%3E%0A")
					}
				}

				&.file-label {

					i {
						background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 342 314.9'%3E%3Cpath fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M175,275.5l135.6-135.6c29.9-29.9,29.9-78.5,0-108.5l0,0c-29.9-29.9-78.5-29.9-108.5,0L25.8,207.7 c-22.5,22.5-22.5,58.9,0,81.3l0,0c22.5,22.5,58.9,22.5,81.3,0l158.2-158.2c15-15,15-39.3,0-54.2l0,0c-15-15-39.3-15-54.2,0 L93.6,194.1'/%3E%3C/svg%3E%0A")
					}
				}
			}
		}

		.send-button {
			font-weight bold
			padding 0 20px 0 10px
			color $primary
			cursor pointer
			background-color transparent
			border none
			transition .3s ease-out

			&:hover {
				color $primary-hover
			}

			@media screen and (max-width: 575px) {
				padding-right 10px
			}
		}
	}

	.chat-sidebar {
		background-color $white
		position relative
		min-height 250px
		overflow auto
		transform translateX(-1px)

		@media screen and (max-width: 991px) {
			height auto
			margin 0
			padding 0
			transform none
			min-height 0
		}

		nav {
			@media screen and (max-width: 991px) {
				display none
			}

			.nav-tabs {

				.nav-link {
					color $white
					border-color transparent

					&:first-child {
						background-color $primary
					}

					&:last-child {
						background-color $secondary
					}
				}
			}
		}

		.tab-content {
			padding 0
			position relative
			display flex

            &:not(.info) {
                border-top none
            }

			@media screen and (max-width: 991px) {
				height auto
				border none
			}

			.tab-pane {
				flex 1
				overflow auto
				max-width 100%

				@media screen and (max-width: 991px) {
					display block
					background-color $white
					border 1px solid $input-default-border
					z-index 1

					& .list{
						transition all .3s ease-out
					}

					&:not(.show-on-mobile) {
						& .list{
							height 0 !important
							transition all .3s ease-out 
						}
					}
				}

				.show {

					@media screen and (max-width: 991px) {
						display block
						transition all .3s ease-out 
					}
				}

				&#nav-information {
					padding 30px 30px 0
					margin-bottom 50px

					@media screen and (max-width: 991px) {
						max-height calc(100vh - 50px - 70px - 50px - 16px)
						padding 15px 15px 0
						margin-bottom 0
						overflow auto
					}
				}

				.separator {
					position relative

					&:before {
						content ''
						position absolute
						top 0
						left 0
						right 0
						height 1px
						background-color $input-default-border
					}
				}

				a:not(.btn) {
					color $primary
					transition color .3s ease-out

					&:hover {
						color $primary-hover
					}
				}

				.property-small-review {
					display flex
					margin-bottom 30px

					img {
						position relative
						height 65px
						width 95px
						object-fit cover
						object-position center
						margin-right 20px

						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							padding-right 95px
						}
					}

					.review-text {
						display flex
						flex-direction column
						justify-content space-between
						width 100%

						small {
							position relative
							height 48px
							font-size 14px
							line-height 16px
							overflow hidden
							overflow-wrap break-word
							text-overflow ellipsis
							line-clamp 3
							box-orient vertical
							display -webkit-box
						}

						.property-show-link {
							display block
							line-height 1em
							font-weight bold

							@media screen and (max-width: 1199px) {
								font-size 14px
							}
						}
					}
				}

				.next-step-wrapper {
					text-align center
					padding-top 30px
					padding-bottom 30px

					.next-icon {
						display block
						position relative
						width 50px
						height 50px
						margin 0 auto 10px
						background-image url('../img/svg/next-step.svg')
						background-size contain
						background-position center
						background-repeat no-repeat
					}

					.btn {
						margin-top 25px
						margin-bottom 0
					}
				}

				.shared-files {
					padding-top 30px

					.collapse-link {
						display flex
						color $black
						margin-bottom 10px


						i {
							margin-left auto
							font-size 18px
							color $primary

							&:before {
								content '\f106'
							}
						}

						&:hover {

							i {
								color $primary-hover
							}
						}

						&.collapsed {

							i {

								&:before {
									content '\f107'
								}
							}
						}
					}

					.collapse,
					.collapsing {

						.shared-file {
							font-size 14px
							display flex
							word-break break-all
							align-items center
							margin-bottom 10px

							.type {
								display inline-block
								position relative
								width 22px
								height 22px
								background-size contain
								background-position center
								background-repeat no-repeat
								margin-right 10px

								&.pdf {
									background-image url('../img/svg/pdf.svg')
								}

								&.doc {
									background-image url('../img/svg/doc.svg')
								}

								&.img {
									background-image url('../img/svg/img.svg')
								}
							}
						}
					}

					 .collapse {
						height calc(100vh - 100px - 80px - 500px)
						overflow auto

						@media screen and (max-width: 991px) {
							height auto
						}
					}
				}

				.report-opponent {
					position absolute
					bottom 0
					left 30px
					right 30px
					font-weight bold
					line-height 16px
					font-size 16px
					padding 17px

					@media screen and (max-width: 991px) {
						position relative
						top 0
						left 0
						right auto
					}
				}

                .properties-for-sell {
                    transition height ease 0.2s
                    position relative
                }

                .properties-for-buy {
                    transition height ease 0.2s
                    height 50%
                    position relative

                    &.sell-collapsed {
                        height calc(100% - 50px)
                    }
                }

				.properties-for-sell,
				.properties-for-buy {

					&.collapsed {
                        height 50px
                    }

                    .list {
                        height calc(100vh - 100px - 80px - 40px - 40px)
                        overflow auto
                    }

					.collapse-link {
						position relative
						height 50px
						background-color $dark-grey
						color $white
						padding 0 30px
						font-family $montserrat
						font-weight bold
						font-size 16px
						display flex
						justify-content center;
                        display: flex;
                        align-items: center;
                        flex-direction: column;

						@media screen and (max-width: 991px) {
							padding 0 15px
						}

						.icons {
							position relative
							display flex
							justify-content space-between
						}

						i {
							font-size 18px
							color $secondary

							&.far {
								width 25px
								text-align right

								&:before {
									content '\f106'
								}
							}

							&.state {
								display inline-block
								position relative
								width 22px
								height 22px
								background-size contain
								background-position center
								background-repeat no-repeat
								background-image url('../img/svg/no-new-message.svg')

								&.new-message {
									background-image url('../img/svg/new-message.svg')
								}
							}
						}

						&:hover {
							color $secondary
						}

						&.collapsed {

							.far:before {
								content '\f107'
							}
						}
					}
				}

				.property-message-group {
					position relative

					&:not(:last-child) {

						&:after {
							content ''
							position absolute
							bottom 0
							left 0
							right 0
							height 1px
							background-color $primary
						}
					}

					.property-small-card {
						display flex
						padding 20px 30px

						@media screen and (max-width: 991px) {
							padding 15px
						}

						img {
							position relative
							height 50px
							width 50px
							object-fit cover
							object-position center
							margin-right 20px
                            min-width 50px;

							@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
								padding-right 50px
							}
						}

                        &.active {
                            .review-text {
                                small {
                                    font-weight bold;
                                }
                            }
                        }

						.review-text {
							display flex
							flex-direction column
							justify-content center
							color $black
							width 100%

							small {
								position relative
								max-height 48px
								font-size 14px
								line-height 16px
								overflow hidden
								overflow-wrap break-word
								text-overflow ellipsis
								-webkit-line-clamp 3
								-webkit-box-orient vertical
								display -webkit-box
							}
						}
					}

					.message-small-card {
						position relative
						padding 10px 30px
						display block
						transition background-color .15s ease-in

						@media screen and (max-width: 991px) {
							padding 10px 15px
						}

						&:before {
							content ''
							position absolute
							top 0
							left 30px
							right 30px
							height 1px
							background-color $input-default-border
							opacity 1
							transition opacity .25s ease-in

							@media screen and (max-width: 991px) {
								left 15px
								right 15px
							}
						}

						//&.active,
						//&:hover {
						//	background-color $secondary
                        //
						//	&:before,
						//	+ .message-small-card:before {
						//		opacity 0
						//	}
						//}
                        &.active {
                            .message-content {
                                .names {
                                    color $primary
                                    font-weight bold
                                    text-decoration underline
                                }
                            }
                        }

						.profile-images-wrapper {
							display inline-block
							position absolute
							left 30px
							top 10px
							width 50px
							height 50px

							@media screen and (max-width: 991px) {
								left 15px
							}

							.profile-img {
								border 2px solid $white
								width 34px
								height 34px
								font-family $montserrat
								font-weight 300
								font-size 16px
								line-height 30px
							}

							&:not(.two-person) {

								.profile-img {
									left 50%
									bottom 50%
									transform translate(-50%, 50%)
								}
							}
						}

						.message-content {
							display flex
							flex-direction column
							margin-left 60px
							color $black
							justify-content center
							position relative
							height 50px

							.names {
								singe-line-ellipsis()
								margin-bottom 5px
							}

							.infos {
								display flex
								justify-content space-between
								color $grey

								.new-messages {

									&.has-new {
										font-weight bold
										color $primary
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.chat + footer {
	display none
}
