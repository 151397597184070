.property-edit {

	sup {
		top 5px
	}
}

.section-title{
	.badge.edit-title-badge{
		font-size: 16px;
		position: absolute;
		top: 0px;
	}
}


.property-show {

	.personal-items {
		display flex
		align-items center
		justify-content flex-end

		@media screen and (max-width: 991px) {
			margin-top 10px
			justify-content flex-start
			margin-bottom 10px
		}

		.personal-item {
			cursor pointer

			svg {
				stroke $grey
				fill transparent
				transition stroke 0.25s ease, fill 0.5s ease
				margin 0 8px
			}

			&:hover {

				svg {
					stroke $primary
					fill transparent
				}
			}

			&.share{
				svg{
					width 16px

					path{
						fill: #959595
					}
				}

				&:hover{
					svg{
						path{
							fill: #4e8aca
						}
					}
				}
			}

			i {
				display inline-block
				position relative
				background-size contain
				background-repeat no-repeat
				background-position center
			}

			.share,
			.star,
			.compare {
				padding 10px
				background-position center
				background-repeat no-repeat
				cursor pointer
			}

			.star {
				width 38px
				height 37.16px
				background-image url('../img/svg/star.svg')
				background-size 18px 17.16px

				&:hover {
					background-image url('../img/svg/star-hover.svg')
				}

				&.active {
					background-image url('../img/svg/star-active.svg')
				}
			}

			.compare {
				width 37px
				height 33.39px
				background-image url('../img/svg/compare.svg')
				background-size 17px 13.39px
				padding-right 0

				&:hover {
					background-image url('../img/svg/compare-hover.svg')
				}

				&.active {
					background-image url('../img/svg/compare-active.svg')
				}
			}
		}
	}

	.property-gallery {
		padding 50px 0 30px

		@media screen and (max-width: 991px) {
			padding 0 0 30px
		}

		.gallery-wrapper {

			@media screen and (min-width: 992px) {
				display flex
				flex-direction column
			}
		}

		.gallery-item {
			position relative
			width 100%
			display block

			&.with-more,
			&.with-video {

				&:before {
					content ''
					position absolute
					top 0
					left 0
					bottom 0
					right 0
					background-color #25252580
					z-index 1
				}
			}

			&.with-video {

				&:after {
					content ''
					position absolute
					top 0
					left 0
					bottom 0
					right 0
					background-image url('../img/svg/play.svg')
					background-size 44px 44px
					background-position center
					background-repeat no-repeat
					z-index 2
				}
			}

			img {
				position relative
				object-fit cover
				object-position center
				font-family 'object-fit: cover, object-position: center;'
				width 100%
				height 100%
			}

			.more-img {
				position absolute
				font-weight normal
				font-size 18px
				line-height 1
				top 50%
				left 50%
				transform translate(-50%, -50%)
				color $white
				z-index 1
			}
		}

		.gallery-left {

			.gallery-item {
				height 490px

				@media screen and (max-width: 991px) {
					height 350px
					margin-bottom 30px
				}

				@media screen and (max-width: 768px) {
					height 250px
				}

				@media screen and (max-width: 575px) {
					height 50vw
				}
			}
		}

		.gallery-wrapper {

			.gallery-item {
				height 100px
				margin-bottom 30px

				@media screen and (max-width: 991px) {
					height 150px
				}

				@media screen and (max-width: 768px) {
					height 125px
				}

				@media screen and (max-width: 575px) {
					height 25vw
				}
			}
		}

		.small-gmaps {
			position relative

			.g-map {
				width 100%
				height 100px
				background-color $light-grey

				@media screen and (max-width: 991px) {
					height 150px
				}

				@media screen and (max-width: 768px) {
					height 125px
				}

				@media screen and (max-width: 575px) {
					height 25vw
				}
			}
		}
	}

	.property-recap-info {
		padding-bottom 50px

		.recap-wrapper {
			display flex
			align-items flex-start
			justify-content space-between
			padding-top 15px

			@media screen and (max-width: 991px) {
				padding-bottom 30px
			}

			@media screen and (max-width: 768px) {
				flex-wrap wrap
			}

			.recap-item {
				position relative
				flex-grow 1
				margin-right 40px

				@media screen and (max-width: 768px) {
					width 50%
					margin-right 0
					margin-bottom 20px
				}

				&:after {
					content ''
					position absolute
					top 0
					bottom 0
					right -10px
					width 1px
					background-color $black

					@media screen and (max-width: 1199px) {
						right: -20px
					}

					@media screen and (max-width: 768px) {
						display none
					}
				}

				&:last-child {
					flex-grow 2

					&:after {
						content none
					}
				}

				.value {
					font-size 24px

					@media screen and (max-width: 768px) {
						font-size 18px
					}
				}
			}
		}

		.buttons {

			.btn {
				margin-bottom 30px

				@media screen and (max-width: 991px) {
					margin-bottom 20px
				}

				&:last-child{
					margin-bottom 0
				}
			}
		}
	}

	.property-description {
		padding 50px 0
		position relative

		&:before {
			content ''
			position absolute
			top 0
			left 0
			right 0
			height 1px
			background-color $input-default-border
		}
	}

	.property-infos {
		padding 50px 0
		position relative

		&:before {
			content ''
			position absolute
			top 0
			left 0
			right 0
			height 1px
			background-color $input-default-border
		}

		@media screen and (max-width: 991px) {
			padding-bottom 30px
		}

		.info-newspaper {
			column-count 3

			@media screen and (max-width: 768px) {
				column-count 2
			}

			@media screen and (max-width: 575px) {
				column-count unset
			}

			.info {
				position relative
				break-inside avoid-column
				page-break-inside avoid
				padding-top 13px
				padding-bottom 1px
				font-family $montserrat
				font-weight 300
				font-size 14px

				.title {
					font-weight bold
				}
			}
		}
	}

	.property-location {

		@media screen and (max-width: 991px) {
			padding-bottom 30px
		}

		.g-map {
			width 100%
			height 500px
			background-color $light-grey

			@media screen and (max-width: 991px) {
				height 300px
			}

			@media screen and (max-width: 768px) {
				height 200px
			}
		}

		.location-address {
			position relative
			margin-top 25px
			padding-left 15px

			&:before {
				content ''
				position absolute
				top 5px
				left 0
				width 7px
				height 14px
				background-image url('../img/svg/location.svg')
				background-size contain
				background-position center
				background-repeat no-repeat
			}
		}
	}

	.info-box {
		background-color $light-grey
		padding 30px
		color $black
		margin-bottom 30px

		@media screen and (max-width: 575px) {
			padding 20px
		}

		.box-title {
			font-weight bold
			margin-bottom 20px
		}

		.list-wrapper {

			.list-item {
				position relative
				padding-left 20px
				margin-bottom 10px

				i {
					position absolute
					width 20px
					height 23px
					top 0
					left 0
					display flex
					align-items center

					svg {
						position relative
						width 9px
						height 8px
					}
				}
			}

			.btn {
				margin-top 25px
			}
		}

		.seller-wrapper {
			text-align center

			.profile-img {
				position relative
				width 100px
				height 100px
				background-size cover
				background-position center
				background-repeat no-repeat
				text-align center
				font-family $montserrat
				font-weight 300
				font-size 48px
				line-height 100px
				margin 0 auto
				background-color $secondary
				border-radius 50%
				cursor default

				&.has-img {
					color transparent
					background-color transparent
				}
			}

			.seller-name {
				padding 20px
			}
		}

		.make-an-offer-wrapper {
			text-align center

			svg {
				position relative
				width 59px
				height 80px
				margin-bottom 20px
			}
		}

		.edit-property-wrapper {
			text-align center

			svg {
				position relative
				width 50px
				height 50px
				transform translateY(-10px)
			}
		}
	}

	.dream-homes {
		margin-top 100px

		@media screen and (max-width: 991px) {
			margin-top 30px
		}
	}

	.stastistics {
		padding-top 50px

		.statistics-head {
			background-color $dark-grey
			color $white
			font-size 16px
			text-align center
			line-height 40px
		}
	}
}

.blurred {
	user-select none
	-webkit-filter blur(5px)
	-moz-filter blur(5px)
	-o-filter blur(5px)
	-ms-filter blur(5px)
	filter url(#blur)
	filter blur(5px)
}

.a2a_default_style .a2a_dd{
	display: none;
}

#autoCompleteAppend .ui-autocomplete{
	position absolute
	z-index 1000
	background #fff
}
