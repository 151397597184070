.dream-homes {
	.btn {
		@media screen and (max-width: 575px) {
			width 100%
		}

		@media screen and (max-width: 400px) {
			font-size 15px
		}
	}
}

.why-ketzo {
	background-image url('../img/why-ketzo-bg.jpg')
	background-size cover
	background-position bottom center
	background-repeat no-repeat
	box-shadow $default-box-shadow

	.nav-pills {
		.nav-link {
			&:after {
				background-color $white
			}
		}
	}

	.tab-text {
		p {
			position relative
			margin-bottom 20px

			@media screen and (max-width: 991px) {
				min-height auto
				margin-bottom 30px
			}
		}

		.btn {
			@media screen and (max-width: 991px) {
				display block
				width 100%
			}
		}
	}
}

.flow {
	padding-top 100px
	padding-bottom 100px

	@media screen and (max-width: 991px) {
		padding-top 50px
		padding-bottom 50px
	}

	img {
		pointer-events none
	}

	.infographic-img {
		margin-bottom 15px
	}

	.infographic-bottom-left {
		margin-top -45px
	}

	.infographic-bottom-right {
		margin-top -73px
	}

	.infographic-bottom-tablet {
		margin-top -170px
	}

	.pr-2-mobile {
		@media screen and (max-width: 768px) {
			padding-right 2.25px

			.flow-tip {
				right 0 !important
			}
		}
	}

	.pl-2-mobile {
		@media screen and (max-width: 768px) {
			padding-left 2.25px

			.flow-tip {
				left 0 !important
			}
		}
	}

	h3 {
		letter-spacing -.5px
		text-align center
		padding-top 12px
		font-size 23px

		@media screen and (max-width: 1199px) {
			margin-bottom 13px
		}
	}

	.flow-row {
		margin-bottom 23px

		@media screen and (max-width: 1199px) {
			margin-bottom 30px
		}

		@media screen and (max-width: 768px) {
			margin-bottom 15px
		}
	}

	.flow-item {
		padding 0
		color $white
		position relative
		height 55px
		font-size 11px
		line-height 15px
		font-weight bold
		display flex
		flex-direction column
		align-items center
		justify-content center
		text-align center
		cursor pointer

		span {
			color #3f2b5f
			display inline-block
		}

		&.has-before {
			&:before {
				content ''
				position absolute
			}
		}

		&.has-after {
			&:after {
				content ''
				position absolute
			}
		}

		+ .flow-tip {
			opacity 0
			display block
			position absolute
			top -10px
			left 15px
			right 15px
			transform translateY(-100%)
			background-color $secondary
			border 2px solid #3c3c3c
			color #3c3c3c
			font-size 12px
			font-weight bold
			padding 18px
			pointer-events none
			transition opacity .15s ease-out
			z-index 1

			@media screen and (max-width: 1199px) {
				top auto
				bottom -10px
				transform translateY(100%)
			}

			&.active {
				opacity 1
			}

			&:after {
				content ''
				display block
				position absolute
				left 50%
				transform translate(-50%, 100%)
				bottom 0
				width 31px
				height 31px
				background-image url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 22.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='125.3px' height='82.5px' viewBox='0 0 125.3 82.5' style='enable-background:new 0 0 125.3 82.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23BEDC1E;%7D .st1%7Bfill:%233C3C3C;%7D .st2%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23BEDC1E;%7D%0A%3C/style%3E%3Cg%3E%3Cpolygon class='st0' points='10.1,5 115.2,5 62.7,74.2 '/%3E%3Cpath class='st1' d='M105.2,10L62.7,66L20.2,10H105.2 M125.3,0H0l62.7,82.5L125.3,0L125.3,0z'/%3E%3C/g%3E%3Cpolyline class='st2' points='103.3,12.3 22,12.5 12.4,0 113,0 '/%3E%3C/svg%3E%0A")
				background-repeat no-repeat
				background-position top
				background-size contain

				@media screen and (max-width: 1199px) {
					top 0
					bottom auto
					transform translate(-50%, -100%) rotate(180deg)
				}
			}
		}

		&.flow-item-1 {
			background-color $primary

			&:after {
				background-image linear-gradient($primary, $thirdly)
				width 3px
				right 30px
				bottom 0
				height 23px
				transform translateY(100%)

				@media screen and (max-width: 1199px) {
					right 57px
					height 30px
					width 5px
				}

				@media screen and (max-width: 768px) {
					height 15px
				}
			}
		}

		&.flow-item-2 {
			background-color $primary

			@media screen and (max-width: 1199px) {
				height 120px
			}

			&:before {
				background-image linear-gradient($primary, $secondary-hover)
				width 3px
				left 30px
				bottom 0
				height 101px
				transform translateY(100%)

				@media screen and (max-width: 1199px) {
					background-image linear-gradient(90deg, $primary, $secondary-hover)
					top 25px
					bottom auto
					right 0
					left auto
					height 5px
					width 30px
					transform translateX(100%)
				}
			}

			&:after {
				background-image linear-gradient($primary, $secondary-hover)
				width 3px
				right 30px
				bottom 0
				height 101px
				transform translateY(100%)

				@media screen and (max-width: 1199px) {
					background-image linear-gradient(90deg, $primary, $secondary-hover)
					bottom 25px
					right 0
					height 5px
					width 30px
					transform translateX(100%)
				}
			}

			& + .flow-tip {
				@media screen and (max-width: 768px) {
					right -15px !important

					&:after {
						transform translate(calc(-50% - 15px), -100%) rotate(180deg)
					}
				}
			}
		}

		&.flow-item-3 {
			background-color $thirdly

			@media screen and (max-width: 1199px) {
				height 43px
			}

			&:before {
				background-color $thirdly
				top 50%
				right 0
				transform translate(100%, -50%)
				height 3px
				width 30px

				@media screen and (max-width: 1199px) {
					display none
				}
			}

			&:after {
				background-image linear-gradient($thirdly, $secondary-hover)
				width 3px
				left 30px
				bottom 0
				height 23px
				transform translateY(100%)

				@media screen and (max-width: 1199px) {
					background-color $thirdly
					background-image none
					height 30px
					width 5px
					left 50%
					transform translate(-50%, 100%)
				}

				@media screen and (max-width: 768px) {
					height 15px
				}
			}
		}

		&.flow-item-4 {
			background-color $thirdly

			@media screen and (max-width: 1199px) {
				height 43px
			}

			&:after {
				background-image linear-gradient($thirdly, $secondary-hover)
				width 3px
				right 30px
				bottom 0
				height 23px
				transform translateY(100%)

				@media screen and (max-width: 1199px) {
					height 30px
					right 57px
					width 5px
				}

				@media screen and (max-width: 768px) {
					height 15px
				}
			}
		}

		&.flow-item-5 {
			background-color $thirdly

			&:before {
				background-color $thirdly
				top 50%
				right 0
				transform translate(100%, -50%)
				height 3px
				width 30px

				@media screen and (max-width: 1199px) {
					display none
				}
			}

			&:after {
				background-image linear-gradient($thirdly, $secondary-hover)
				width 3px
				left 30px
				bottom 0
				height 23px
				transform translateY(100%)

				@media screen and (max-width: 1199px) {
					background-color $thirdly
					background-image none
					left 50%
					height 30px
					width 5px
					transform translate(-50%, 100%)
				}

				@media screen and (max-width: 768px) {
					height 15px
				}
			}
		}

		&.flow-item-6 {
			background-color $thirdly
		}

		&.flow-item-7 {
			background-color $secondary-hover

			&:after {
				@media screen and (max-width: 1199px) {
					background-image linear-gradient($secondary-hover, $thirdly)
					width 5px
					left 57px
					bottom 0
					height 30px
					transform translateY(100%)
				}

				@media screen and (max-width: 768px) {
					height 15px
				}
			}
		}

		&.flow-item-8 {
			background-color $secondary-hover

			@media screen and (max-width: 1199px) {
				margin-bottom 10px
			}
		}

		&.flow-item-9 {
			background-color $secondary-hover

			&:after {
				@media screen and (max-width: 1199px) {
					background-image linear-gradient($secondary-hover, $thirdly)
					width 3px
					left 57px
					bottom 0
					height 30px
					width 5px
					transform translateY(100%)
				}

				@media screen and (max-width: 768px) {
					height 15px
				}
			}
		}
	}
}

.flow-old {
	.section-title {
		@media screen and (max-width: 1199px) and (min-width: 992px) {
			font-size 30px
		}

		&.margin-0-mobile {
			@media screen and (max-width: 991px) {
				margin-bottom 0
			}
		}
	}

	.flow-wrapper {
		position relative

		&:before {
			content ''
			position absolute
			top 40px
			bottom 40px
			border-right 2px dashed #CCCCCC

			@media screen and (max-width: 991px) {
				display none
			}
		}

		.flow-item {
			display flex
			align-items center
			position relative

			img {
				height 80px
				width 80px

				@media screen and (max-width: 991px) {
					height 120px
					width 120px
					margin-bottom 30px
				}

				@media screen and (max-width: 768px) {
					height 60px
					width 60px
					margin-bottom 20px
				}
			}

			p {
				@media screen and (max-width: 1199px) and (min-width: 992px) {
					font-size 14px
				}

				@media screen and (max-width: 768px) {
					max-width 220px !important
				}
			}

			&:before {
				content ''
				position absolute
				top 50%
				height 8px
				width 8px
				background-color $primary
				border-radius 50%

				@media screen and (max-width: 991px) {
					display none
				}
			}

			&:after {
				content ''
				position absolute
				top 40px
				width 30px
				border-top 2px dashed #CCCCCC

				@media screen and (max-width: 991px) {
					display none
				}
			}

			&:not(:last-child) {
				margin-bottom 30px

				@media screen and (max-width: 991px) {
					margin-bottom 50px
				}

				@media screen and (max-width: 768px) {
					margin-bottom 35px
				}
			}

			&:last-child {
				@media screen and (max-width: 991px) {
					margin-bottom 50px
				}
			}

			&:nth-child(1) {
				&:before {
					background-color $primary
				}

				p {
					@media screen and (max-width: 991px) {
						max-width 280px
					}
				}
			}

			&:nth-child(2) {
				&:before {
					background-color $primary-hover
				}

				p {
					max-width 245px
				}
			}

			&:nth-child(3) {
				&:before {
					background-color $thirdly
				}

				p, img {
					opacity 0
					pointer-events none

					@media screen and (max-width: 991px) {
						opacity 1
						pointer-events auto
					}
				}

				p {
					@media screen and (max-width: 991px) {
						max-width 280px
					}
				}
			}

			&:nth-child(4) {
				&:before {
					background-color $secondary-hover
				}

				p {
					max-width 185px

					@media screen and (max-width: 991px) {
						max-width 280px
					}
				}
			}

			&:nth-child(5) {
				&:before {
					background-color $secondary
				}

				p, img {
					opacity 0
					pointer-events none

					@media screen and (max-width: 991px) {
						opacity 1
						pointer-events auto
					}
				}

				p {
					@media screen and (max-width: 991px) {
						max-width 280px
					}
				}
			}

			&:nth-child(6) {
				&:before {
					background-color $secondary-hover
				}
			}
		}

		&.left-side {
			&:before {
				right -60px
			}

			.flow-item {
				justify-content flex-end

				@media screen and (max-width: 991px) {
					flex-direction column-reverse
					justify-content center
				}

				p {
					text-align right
					margin-right 15px

					@media screen and (max-width: 991px) {
						text-align center
						margin-right 0
					}
				}

				&:before {
					right -30px
					transform translate(calc(50% - 8px), -50%)
				}

				&:after {
					right -60px
					transform translateY(-50%)
				}

				&:nth-child(3), &:nth-child(5) {
					&:before {
						right -90px
						transform translate(calc(50% + 8px), -50%)
					}

					&:after {
						right -90px
					}
				}

				&:nth-child(6) {
					p {
						max-width 110px

						@media screen and (max-width: 991px) {
							max-width none
						}
					}
				}
			}
		}

		&.right-side {
			&:before {
				left -60px
			}

			.flow-item {
				justify-content flex-start

				@media screen and (max-width: 991px) {
					flex-direction column
					justify-content center
				}

				p {
					text-align left
					margin-left 15px

					@media screen and (max-width: 991px) {
						text-align center
						margin-left 0
					}
				}

				&:before {
					left -30px
					transform translate(calc(-50% + 8px), -50%)
				}

				&:after {
					left -60px
					transform translateY(-50%)
				}

				&:nth-child(3), &:nth-child(5) {
					&:before {
						left -90px
						transform translate(calc(-50% - 8px), -50%)
					}

					&:after {
						left -90px
					}
				}

				&:nth-child(5) {
					p {
						max-width 150px
					}
				}
			}
		}
	}

	.extra-flow-item {
		position relative
		text-align center

		&:nth-child(1) {
			top 200px
		}

		&:nth-child(2) {
			top 250px
		}

		img {
			height 80px
			width 82px
			margin-bottom 20px
		}

		p {
			@media screen and (max-width: 1199px) and (min-width: 992px) {
				font-size 14px
			}
		}
	}
}

.lawyers {
	background-color $light-grey

	.lawyers-wrapper {
		display flex
		justify-content space-between

		@media screen and (max-width: 575px) {
			flex-wrap wrap
			justify-content space-around
		}

		.lawyer-card:not(:last-child) {
			@media screen and (max-width: 768px) {
				margin-bottom 20px
			}
		}
	}
}