@font-face {
	font-family 'lg'
	src url("../fonts/lightgallery/lg.eot?n1z373")
	src url("../fonts/lightgallery/lg.eot?#iefixn1z373") format("embedded-opentype"),
		url("../fonts/lightgallery/lg.woff?n1z373") format("woff"),
		url("../fonts/lightgallery/lg.ttf?n1z373") format("truetype"),
		url("../fonts/lightgallery/lg.svg?n1z373#lg") format("svg")
	font-weight normal
	font-style normal
}

.lg-icon {
	font-family 'lg'
	speak none
	font-style normal
	font-weight normal
	font-variant normal
	text-transform none
	line-height 1
	font-smoothing antialiased
	osx-font-smoothing grayscale
}

.lg-actions .lg-next, .lg-actions .lg-prev {
	background-color rgba(0, 0, 0, 0.45)
	border-radius 2px
	color $grey
	cursor pointer
	display block
	font-size 22px
	margin-top -10px
	padding 8px 10px 9px
	position absolute
	top 50%
	z-index 1080
	border none
	outline none
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
	pointer-events none
	opacity 0.5
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
	color $white
}

.lg-actions {

	.lg-next {
		right 20px

		&:before {
			content "\e095"
		}
	}
	.lg-prev {
		left 20px

		&:after {
			content "\e094"
		}
	}
}

@keyframes lg-right-end {
	0% {
		left 0
	}
	50% {
		left -30px
	}
	100% {
		left 0
	}
}

@keyframes lg-left-end {
	0% {
		left 0
	}
	50% {
		left 30px
	}
	100% {
		left 0
	}
}

.lg-outer {

	&.lg-right-end {

		.lg-object {
			animation lg-right-end 0.3s
			animation lgend 0.3s
			position relative
		}
	}
}

.lg-outer {
	width 100%
	height 100%
	position fixed
	top 0
	left 0
	z-index 1050
	text-align left
	opacity 0
	transition opacity 0.15s ease 0s

	&.lg-left-end {

		.lg-object {
			animation lg-left-end 0.3s
			animation lgend 0.3s
			position relative
		}
	}

	.lg-thumb-outer {
		background-color $black
		bottom 0
		position absolute
		width 100%
		z-index 1080
		max-height 350px
		transform translate3d(0, 100%, 0)
		transition transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s

		&.lg-grab {

			.lg-thumb-item {
				cursor grab
			}
		}

		&.lg-grabbing {

			.lg-thumb-item {
				cursor move
				cursor grabbing
			}
		}

		&.lg-dragging {

			.lg-thumb {
				transition-duration 0s !important
			}
		}
	}

	&.lg-thumb-open {

		.lg-thumb-outer {
			transform translate3d(0, 0%, 0)
		}
		
		.lg-img-wrap {
			padding-bottom 100px
		}
	}

	.lg-thumb {
		padding 10px 0
		height 100%
		margin 0 10px -5px
	}

	.lg-thumb-item {
		cursor pointer
		float left
		overflow hidden
		height 100%
		border 2px solid $white
		border-radius 4px
		margin-bottom 5px

		img {
			width 100%
			height 100%
			object-fit cover
		}
	}

	&.lg-has-thumb {

		.lg-item {
			padding-bottom 120px
		}
	}

	&.lg-can-toggle {

		.lg-item {
			padding-bottom 0
		}
	}

	&.lg-pull-caption-up {

		.lg-sub-html {
			transition bottom 0.25s ease
		}

		&.lg-thumb-open {

			.lg-sub-html {
				bottom 100px
			}
		}
	}

	.lg-toogle-thumb {
		background-color $black
		border-radius 2px 2px 0 0
		color $grey
		cursor pointer
		font-size 24px
		height 39px
		line-height 27px
		padding 5px 0
		position absolute
		right 20px
		text-align center
		top -39px
		width 50px

		&:after {
			content "\e1ff"
		}
		&:hover {
			color $white
		}
	}

	.lg-video-cont {
		display inline-block
		vertical-align middle
		max-width 1140px
		max-height 100%
		width 100%
		padding 0 5px
	}

	.lg-video {
		width 100%
		height 0
		padding-bottom 56.25%
		overflow hidden
		position relative

		.lg-object {
			display inline-block
			position absolute
			top 0
			left 0
			width 100% !important
			height 100% !important
		}

		.lg-video-play {
			width 84px
			height 59px
			position absolute
			left 50%
			top 50%
			margin-left -42px
			margin-top -30px
			z-index 1080
			cursor pointer
		}
	}

	.lg-has-iframe {

		.lg-video {
			overflow-scrolling touch
			overflow auto
		}
	}

	.lg-has-vimeo {

		.lg-video-play {
			background url("../img/lightgallery/vimeo-play.png") no-repeat scroll 0 0 transparent
		}

		&:hover {

			.lg-video-play {
				background url("../img/lightgallery/vimeo-play.png") no-repeat scroll 0 -58px transparent
			}
		}
	}

	.lg-has-html5 {

		.lg-video-play {
			background transparent url("../img/lightgallery/video-play.png") no-repeat scroll 0 0
			height 64px
			margin-left -32px
			margin-top -32px
			width 64px
			opacity 0.8
		}

		&:hover {

			.lg-video-play {
				opacity 1
			}
		}
	}

	.lg-has-youtube {

		.lg-video-play {
			background url("../img/lightgallery/youtube-play.png") no-repeat scroll 0 0 transparent
		}

		&:hover {

			.lg-video-play {
				background url("../img/lightgallery/youtube-play.png") no-repeat scroll 0 -60px transparent
			}
		}
	}

	.lg-video-object {
		width 100% !important
		height 100% !important
		position absolute;
		top 0
		left 0
	}

	.lg-has-video {

		.lg-video-object {
			visibility hidden
		}

		&.lg-video-playing {

			.lg-video-object {
				visibility visible
			}
		}
	}

	&.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
		transition transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s
	}

	&.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable  .lg-img-wrap {
		transition left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s
	}

	.lg-item {
		background url("../img/lightgallery/loading.gif") no-repeat scroll center center transparent
		display none !important

		&.lg-complete {
			background-image none

			&.lg-zoomable {

				.lg-img-wrap {
					transform translate3d(0, 0, 0)
					backface-visibility hidden
				}

				.lg-image {
					transform scale3d(1, 1, 1)
					transition transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important
					transform-origin 0 0
					backface-visibility hidden
				}
			}
		}

		&.lg-current {
			z-index 1060
		}
	}

	.lg-pager-outer {
		bottom 60px
		left 0
		position absolute
		right 0
		text-align center
		z-index 1080
		height 10px

		&.lg-pager-hover {

			.lg-pager-cont {
				overflow visible
			}
		}
	}

	.lg-pager-cont {
		cursor pointer
		display inline-block
		overflow hidden
		position relative
		vertical-align top
		margin 0 5px

		&:hover {

			.lg-pager-thumb-cont {
				opacity 1
				transform translate3d(0, 0, 0)
			}
		}

		&.lg-pager-active {

			.lg-pager {
				box-shadow 0 0 0 2px white inset
			}
		}
	}

	.lg-pager-thumb-cont {
		background-color $white
		color $white
		bottom 100%
		height 83px
		left 0
		margin-bottom 20px
		margin-left -60px
		opacity 0
		padding 5px
		position absolute
		width 120px
		border-radius 3px
		transition opacity 0.15s ease 0s, transform 0.15s ease 0s
		transform translate3d(0, 5px, 0)

		img {
			width 100%
			height 100%
		}
	}

	.lg-pager {
		background-color rgba(255, 255, 255, 0.5)
		border-radius 50%
		box-shadow 0 0 0 8px rgba(255, 255, 255, 0.7) inset
		display block
		height 12px
		transition box-shadow 0.3s ease 0s
		width 12px
	}

	.lg-caret {
		border-left 10px solid transparent
		border-right 10px solid transparent
		border-top 10px dashed
		bottom -10px
		display inline-block
		height 0
		left 50%
		margin-left -5px
		position absolute
		vertical-align middle
		width 0
	}

	#lg-dropdown-overlay {
		background-color rgba(0, 0, 0, 0.25)
		bottom 0
		cursor default
		left 0
		position fixed
		right 0
		top 0
		z-index 1081
		opacity 0
		visibility hidden
		transition visibility 0s linear 0.18s, opacity 0.18s linear 0s
	}

	&.lg-dropdown-active {

		#lg-share {
			color $white
		}
	}

	.lg-dropdown {
		background-color $white
		border-radius 2px
		font-size 14px
		listtype none
		margin 0
		padding 10px 0
		position absolute
		right 0
		text-align left
		top 50px
		opacity 0
		visibility hidden
		transform translate3d(0, 5px, 0)
		transition transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s

		&:after {
			content ''
			display block
			height 0
			width 0
			position absolute
			border 8px solid transparent
			bordercolor $white
			right 16px
			top -16px
		}

		& > li {

			&:last-child {
				margin-bottom 0
			}
		}

		a {
			color $dark-grey
			display block
			white-space pre
			padding 4px 12px
			font-family "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif
			font-size 12px

			&:hover {
				background-color rgba(0, 0, 0, 0.07)
			}
		}

		.lg-dropdown-text {
			display inline-block
			line-height 1
			margin-top -3px
			vertical-align middle
		}

		.lg-icon {
			color $dark-grey
			display inline-block
			float none
			font-size 20px
			height auto
			line-height 1
			margin-right 8px
			padding 0
			vertical-align middle
			width auto
		}
	}

	#lg-share {
		position relative

		&:after {
			content "\e80d"
		}
	}

	#lg-share-facebook {

		.lg-icon {
			color $primary

			&:after {
				content "\e901"
			}
		}
	}
	#lg-share-twitter {

		.lg-icon {
			color $primary

			&:after {
				content "\e904"
			}
		}
	}

	#lg-share-googleplus {

		.lg-icon {
			color $primary

			&:after {
				content "\e902"
			}
		}
	}

	#lg-share-pinterest {

		.lg-icon {
			color $primary

			&:after {
				content "\e903"
			}
		}
	}

	* {
		box-sizing border-box
	}

	&.lg-visible {
		opacity 1
	}

	&.lg-grab {

		img {

			&.lg-object {
				cursor grab
			}
		}
	}

	&.lg-grabbing {

		img {

			&.lg-object {
				cursor move
				cursor grabbing
			}
		}
	}

	.lg {
		height 100%
		width 100%
		position relative
		overflow hidden
		margin-left auto
		margin-right auto
		max-width 100%
		max-height 100%
	}

	.lg-inner {
		width 100%
		height 100%
		position absolute
		left 0
		top 0
		white-space nowrap
	}

	&.lg-css {

		.lg-current {
			display inline-block !important
		}
	}

	.lg-img-wrap {
		position absolute
		padding 0 5px
		left 0
		right 0
		top 0
		bottom 0
		transition padding-bottom 0.2s ease-in
	}

	.lg-image {
		display inline-block
		vertical-align middle
		max-width 100%
		max-height 100%
		width auto !important
		height auto !important
	}

	.lg-empty-html {
		display none
	}

	&.lg-hide-download {

		#lg-download {
			display none
		}
	}
}

.lg-toolbar {
	z-index 1082
	left 0
	position absolute
	top 0
	width 100%
	background-color rgba(0, 0, 0, 0.45)

	.lg-icon {
		color $grey
		cursor pointer
		float right
		font-size 24px
		height 47px
		line-height 27px
		padding 10px 0
		text-align center
		width 50px
		text-decoration none !important
		outline medium none
		transition color 0.2s linear

		&:hover {
			color $white
		}
	}

	.lg-close:after {
		content "\e070"
	}

	.lg-download:after {
		content "\e0f2"
	}
}

.lg-sub-html {
	background-color rgba(0, 0, 0, 0.45)
	bottom 0
	color #EEE
	font-size 16px
	left 0
	padding 10px 40px
	position fixed
	right 0
	text-align center
	z-index 1080
	display none

	h4 {
		margin 0
		font-size 13px
		font-weight bold
	}

	p {
		font-size 12px
		margin 5px 0 0
	}
}

#lg-counter {
	color $grey
	display inline-block
	font-size 16px
	padding-left 20px
	padding-top 12px
	vertical-align middle
}

.lg-toolbar,
.lg-prev,
.lg-next {
	opacity 1
	transition transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear
}

.lg-hide-items {

	.lg-prev {
		opacity 0
		transform translate3d(-10px, 0, 0)
	}

	.lg-next {
		opacity 0
		transform translate3d(10px, 0, 0)
	}

	.lg-toolbar {
		opacity 0
		transform translate3d(0, -10px, 0)
	}
}

body {

	&:not(.lg-from-hash) {

		.lg-outer {

			&.lg-start-zoom {

				.lg-object {
					transform scale3d(0.5, 0.5, 0.5)
					opacity 0
					transition transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important
					transform-origin 50% 50%
				}

				.lg-item {

					&.lg-complete {

						.lg-object {
							transform scale3d(1, 1, 1)
							opacity 1
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1025px) {

	.lg-outer {

		.lg-thumb-item {
			transition border-color 0.25s ease
		}
	}
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
	border-color $primary
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
	display none
}

.lg-progress-bar {
	background-color $grey
	height 5px
	left 0
	position absolute
	top 0
	width 100%
	z-index 1083
	opacity 0
	transition opacity 0.08s ease 0s

	.lg-progress {
		background-color $primary
		height 5px
		width 0
	}

	&.lg-start {

		.lg-progress {
			width 100%
		}
	}
}

.lg-show-autoplay {

	.lg-progress-bar {
		opacity 1
	}

	.lg-autoplay-button:after {
		content "\e01a"
	}
}

.lg-autoplay-button:after {
	content "\e01d"
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
	transition-duration 0s
}

#lg-zoom-in {

	&:after {
		content "\e311"
	}
}

#lg-actual-size {
	font-size 20px

	&:after {
		content "\e033"
	}
}

#lg-zoom-out {
	opacity 0.5
	pointer-events none

	&:after {
		content "\e312"
	}
}

.lg-zoomed {

	#lg-zoom-out {
		opacity 1
		pointer-events auto
	}
}

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
	box-shadow 0 0 0 8px white inset
}

.lg-fullscreen:after {
	content "\e20c"
}

.lg-fullscreen-on .lg-fullscreen:after {
	content "\e20d"
}

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
	transition-delay 0s
	transform translate3d(0, 0px, 0)
	opacity 1
	visibility visible
}

.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
	color $dark-grey
}

.lg-group {

	&:after {
		content ''
		display table
		clear both
	}
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
	transition-duration inherit !important
	transition-timing-function inherit !important
	transitionfunction inherit !important
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
	transition-duration 0s !important
	opacity 1
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
	display inline-block !important
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
	display inline-block
	text-align center
	position absolute
	width 100%
	height 100%
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
	content ''
	display inline-block
	height 50%
	width 1px
	margin-right -1px
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
	opacity 0
	transition opacity 0.15s ease 0s
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
	opacity 1
}

.lg-backdrop {
	position fixed
	top 0
	left 0
	right 0
	bottom 0
	z-index 1040
	background-color $black
	opacity 0
	transition opacity 0.15s ease 0s

	&.in {
		opacity 1
	}
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
	transition none 0s ease 0s !important
}

.lg-css3 {

	&.lg-use-css3 {

		.lg-item {
			backface-visibility hidden
		}
	}

	&.lg-use-left {

		.lg-item {
			backface-visibility hidden
		}
	}

	&.lg-fade {

		.lg-item {
			opacity 0

			&.lg-current {
				opacity 1
			}
		}
	}

	&.lg-slide {

		&.lg-use-css3 {

			.lg-item {
				opacity 0

				&.lg-prev-slide {
					transform translate3d(-100%, 0, 0)
				}

				&.lg-next-slide {
					transform translate3d(100%, 0, 0)
				}

				&.lg-current {
					transform translate3d(0, 0, 0)
					opacity 1
				}
			}
		}

		&.lg-use-left {

			.lg-item {
				opacity 0
				position absolute
				left 0

				&.lg-prev-slide {
					left -100%
				}

				&.lg-next-slide {
					left 100%
				}

				&.lg-current {
					left 0
					opacity 1
				}
			}
		}
	}
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
	transition opacity 0.1s ease 0s
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
	transition transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
	transition left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s
}