.nav-pills {

	.nav-link {
		font-family $montserrat
		font-weight bold
		font-size 14px
		position relative
		padding 9px 0 9px 20px
		transition all .3s ease-out
		backface-visibility hidden

		&:before {
			content ''
			position absolute
			top 50%
			left 20px
			width 9px
			height 8px
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73 62.5'%3E%3Cpath fill='%23008CCD' d='M73,31.2L39.4,0H0v62.5h39.4L73,31.2L73,31.2z'/%3E%3C/svg%3E%0A")
			background-position center
			background-size cover
			background-repeat no-repeat
			transform translateY(-50%)
			display none
			transition background-image .3s ease-out
		}

		&:after {
			content ''
			position absolute
			bottom 0
			left 0
			right 0
			height 1px
			background-color $black
		}

		&:hover {
			color $primary
		}

		&.active {
			padding-left 40px
			background-color transparent
			color $primary

			&:before {
				display block
			}
		}

		&.error{
			color $error-color

			&:before {
				background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73 62.5'%3E%3Cpath fill='%23ff0000' d='M73,31.2L39.4,0H0v62.5h39.4L73,31.2L73,31.2z'/%3E%3C/svg%3E%0A")
			}
		}

		&.warning{
			color #bedc1e
		}
	}
}

.tab-mobile-title {

	@media screen and (min-width: 992px) {
		display none
	}

	&.warning{
		color #bedc1e
	}

	.tab-mobile-link {
		font-family $montserrat
		font-weight 300
		font-size 15px
		position relative
		transition all .3s ease-out
		backface-visibility hidden
		display block
		padding 15px 0 15px 20px
		cursor pointer

		&:before {
			content ''
			position absolute
			top 50%
			left 0
			width 9px
			height 8px
			background-image: url('../img/svg/tab-icon.svg')
			background-position center
			background-size contain
			background-repeat no-repeat
			transform translateY(-50%)
			transition transform .3s ease-in-out
		}

		&:after {
			content ''
			position absolute
			bottom 0
			left 0
			right 0
			height 1px
			background-color $white
			opacity 1
			transition opacity .3s ease-in-out
		}

		&:hover {
			color $primary
		}

		&:not(.collapsed) {
			padding-left 20px
			background-color transparent
			font-weight bold
			color $primary

			&:before {
				transform translateY(-50%) rotate(90deg)
			}

			&:after {
				opacity 0
			}
		}
	}
}

.tab-content {

	@media screen and (max-width: 991px) {

		& > .tab-pane {
			display block

			&.deal-tab{
			    display none

			    &.show{
			        display block
			    }
			}
		}
		.fade:not(.show) {
			opacity 1
		}
	}

	.tab-text {

		@media screen and (min-width: 992px) {
			display block
		}
	}
}

.nav-tabs {

	.nav-link {
		position relative
		width 50%
		border-radius 0
		padding 0
		height 30px
		line-height 30px
		text-align center
		font-family $montserrat
		font-weight normal
		font-size 16px
		color $black
		margin-top 10px
		border-color $input-default-border $input-default-border $input-default-border
		transition color .3s ease-out

		&:first-child {
			border-right none
		}

		&:hover {
			color $primary
			border-color $input-default-border $input-default-border $input-default-border
		}

		&.active {
			border 1px solid $input-default-border
			border-color $input-default-border $input-default-border $white
			border-bottom none
			color $primary
			font-weight bold
			height 40px
			line-height 40px
			margin-top 0
		}

		&.active + .nav-link {
			border-color $input-default-border $input-default-border $input-default-border $white
			border-left none
		}

		.new-message {
			display inline-block
			position relative
			width 22px
			height 22px
			background-size contain
			background-position center
			background-repeat no-repeat
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 360'%3E%3Crect x='9' y='135' fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' width='342' height='216'/%3E%3Cpolyline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='351,135 180,261 9,135 '/%3E%3Cpolyline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='145.8,235.8 9,351 351,351 214.2,235.8 '/%3E%3Cline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='180' y1='9' x2='180' y2='63'/%3E%3Ccircle fill='%23008ccd' cx='180' cy='99' r='9'/%3E%3C/svg%3E%0A")
			margin-left 5px
		}
	}
}
