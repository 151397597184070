select {
	border 1px solid $black
	font-family $montserrat
	font-size 15px !important
	line-height 18px
}

.select-group {
	text-align right
}

.SumoSelect p {
	margin 0
}

.SumoSelect, .custom-select {
	border-bottom 1px solid $input-default-border
	width 100%
	appearance none
	font-family $montserrat
	height 34px

	&:hover {
		border-bottom 1px solid $primary
	}

	@media all and (max-width: 1199px) {
		margin-left 0
	}
}

.SumoSelect.open, .custom-select {
	border-bottom 1px solid $primary
}

.filters-wrapper .SumoSelect {
	margin-left 30px

	@media screen and (max-width: 1199px) {
		margin-left 0
	}
}

.contact-form .SumoSelect {
	border none
	margin-left 0
	width 100%

	.sumo_subject {
		border none
		margin-left 0
		width 100%
	}

	&>.CaptionCont {
		padding-left 24px

		@media all and (max-width: 991px) {
			padding-left 10px
		}
	}
}

.SelectBox {
	cursor pointer
	outline none
	padding 0
	line-height 34px

	&:focus, &:hover {
		outline none
	}
}

.sumoStopScroll {
	overflow hidden
}

/* Filtering style */
.SumoSelect .hidden {
	display none
}

.SumoSelect .search-txt {
	display none
	outline none
}

.SumoSelect .no-match {
	display none
	padding 6px
}

.SumoSelect.open .search-txt {
	border none
	border-radius 5px
	box-sizing border-box
	display inline-block
	left 0
	margin 0
	padding 5px 8px
	position absolute
	top 0
	width 100%
}

.SumoSelect.open>.search>span, .SumoSelect.open>.search>label {
	visibility hidden
}

/* this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */
.SelectClass, .SumoUnder {
	border none
	box-sizing border-box
	height 100%
	left 0
	opacity 0
	position absolute
	right 0
	top 0
	width 100%
	-khtml-opacity 0
	-moz-box-sizing border-box
	-moz-opacity 0
	-webkit-box-sizing border-box
}

.SelectClass {
	z-index 1
}

.SumoSelect > .optWrapper > .options li.opt label, .SumoSelect > .CaptionCont, .SumoSelect .select-all > label {
	color $black
	user-select none
	-khtml-user-select none
	-moz-user-select none
	-o-user-select none
	-webkit-user-select none
}

.multi-opt {

	label {
		color #8a8a8a !important
	}

	&.selected {

		label {
			color $black !important
		}
	}
}

.SumoSelect {
	display block
	outline none
	position relative

	&:hover {

		.CaptionCont > label > i {
			background-image url('../img/svg/select-arrow-hover.svg')
		}
	}
}

.SumoSelect:focus > .CaptionCont, .SumoSelect:hover > .CaptionCont, .SumoSelect.open > .CaptionCont {
	border none
	box-shadow none
}

.SumoSelect > .CaptionCont {
	margin 0
	min-height 34px
	position relative
	text-align left
	color #cbcbcb
}

.SumoSelect > .CaptionCont > span {
	cursor pointer
	display block
	overflow hidden
	text-overflow ellipsis
	color $grey

	&.changed {
		color black
	}
}

/* placeholder style */
.SumoSelect > .CaptionCont > span.placeholder {
	color $grey
}

.SumoSelect > .CaptionCont > label {
	bottom 0
	cursor pointer
	position absolute
	right 0
	top 0
	width 30px
}

.SumoSelect > .CaptionCont > label > i {
	display block
	background-image url('../img/svg/select-arrow.svg')
	background-position center center
	background-repeat no-repeat
	position absolute
	top 0
	bottom 0
	right 0
	width 16px
	margin auto
}

.SumoSelect.open > .CaptionCont > label > i {
	background-image url('../img/svg/select-arrow-hover.svg')
}

.SumoSelect > .optWrapper {
	box-shadow 2px 3px 3px rgba(0, 0, 0, .11)
	box-sizing border-box
	display none
	left -1px
	outline 1px solid $black
	overflow hidden
	position absolute
	top 49px
	width 100.7%
	z-index 1000
	-moz-box-sizing border-box
	-webkit-box-sizing border-box

	@media screen and (max-width: 1199px) {
		width 101%
	}

	@media screen and (max-width: 991px) {
		width 100.4%
	}
}

.SumoSelect.open > .optWrapper {
	display block
	top 34px
	left 0
	width 100%
	box-shadow: 0 10px 30px rgba(0,0,0,0.15);
	background $white
}

.SumoSelect.open > .optWrapper.up {
	bottom 100%
	margin-bottom 5px
	top auto
}

.SumoSelect > .optWrapper ul {
	display block
	list-style none
	margin 0
	overflow auto
	padding 0
}

.SumoSelect > .optWrapper > .options {
	position relative
}

.SumoSelect > .optWrapper > .options li.group.disabled > label {
	opacity 1
	font-weight 700 !important
}

.SumoSelect > .optWrapper > .options li ul li.opt {
	padding-left 22px
}

.SumoSelect > .optWrapper.multiple > .options li ul li.opt {
	padding-left 50px
}

.SumoSelect > .optWrapper.isFloating > .options {
	box-shadow 0 0 100px #595959
	max-height 100%
}

.SumoSelect > .optWrapper > .options li.opt {
	padding 6px
	padding-left 12px
	position relative
	background-color $white

	&.option-child {
		padding-left 20px
	}

	&.option-parent {

		label {
			font-weight 700 !important
		}
	}

	&.selected {
		color $primary
	}
}

.SumoSelect > .optWrapper > .options > li.opt:first-child {
	border-radius 2px 2px 0 0
}

.SumoSelect > .optWrapper > .options > li.opt:last-child {
	margin-bottom 12px
	border-bottom none
	border-radius 0 0 2px 2px
}

.SumoSelect > .optWrapper > .options li.opt:hover {

	label {
		color $primary !important
	}

	i {
		border 1px solid $primary !important
	}
}

.SumoSelect > .optWrapper > .options li.opt.sel {
	background-color $primary
	border none
	color $white
}

.SumoSelect > .optWrapper > .options li label {
	cursor pointer
	display block
	font-family $montserrat
	font-size 16px
	line-height 18px
	font-weight 300
	overflow hidden
	padding-bottom 0
	padding-top 10px
	text-align left
	text-overflow ellipsis
	white-space nowrap
}

.SumoSelect > .optWrapper > .options li span {
	display none
}

.SumoSelect > .optWrapper > .options li.group > label {
	cursor default
	padding 8px 6px 0
	font-weight bold !important
}

/* Floating styles */
.SumoSelect > .optWrapper.isFloating {
	bottom 0
	left 0
	margin auto
	max-height 90%
	position fixed
	right 0
	top 0
	width 90%
}

/* disabled state */
.SumoSelect > .optWrapper > .options li.opt.disabled {
	background-color inherit
	pointer-events none
	padding-left 10px
	display none
}

.SumoSelect > .optWrapper > .options li.opt.disabled * { /* IE 5-7 */
	opacity 1
	font-weight 700 !important
}

/* styling for multiple select */
.SumoSelect > .optWrapper.multiple > .options li.opt {
	cursor pointer
	padding-left 35px
}

.SumoSelect > .optWrapper.multiple > .options li.opt span, .SumoSelect .select-all > span {
	bottom 0
	display block
	margin-left -35px
	position absolute
	top 0
	width 30px
}

.SumoSelect > .optWrapper.multiple > .options li.opt span i, .SumoSelect .select-all > span i {
	background-color $white
	border 1px solid $grey
	bottom 0
	height 14px
	left 0
	margin auto
	position absolute
	right 0
	top 9px
	width 14px
}

.SumoSelect > .optWrapper > .MultiControls {
	background-color $white
	border-radius 0 0 3px 3px
	border-top 1px solid $primary
	box-shadow 0 0 2px rgba(0, 0, 0, .13)
	display none
}

.SumoSelect > .optWrapper.multiple.isFloating > .MultiControls {
	bottom 0
	display block
	margin-top 5px
	position absolute
	width 100%
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls {
	display block
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p {
	padding 6px
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p {
	box-sizing border-box
	cursor pointer
	display inline-block
	padding 12px
	text-align center
	width 50%
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
	background-color $primary-hover
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
	border-radius 0 0 0 3px
	border-right 1px solid $primary
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnCancel {
	border-radius 0 0 3px 0
}

/* styling for select on popup mode */
.SumoSelect > .optWrapper.isFloating > .options li.opt {
	padding 12px 6px
}

/* styling for only multiple select on popup mode */
.SumoSelect > .optWrapper.multiple.isFloating > .options li.opt {
	padding-left 35px
}

.SumoSelect > .optWrapper.multiple.isFloating {
	padding-bottom 43px
}

.SumoSelect > .optWrapper.multiple > .options li.opt.selected span i,
.SumoSelect .select-all.selected > span i,
.SumoSelect .select-all.partial > span i {
	background-color $primary
	background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC')
	background-position center center
	background-repeat no-repeat
	border-color $primary
	box-shadow none
}

.SumoSelect>.optWrapper>.options li.opt.selected label {
	color $primary
}

/* disabled state */
.SumoSelect.disabled {
	cursor not-allowed
	opacity 1
}

.SumoSelect.disabled > .CaptionCont {
	border-color $primary
	box-shadow none
}

/* *Select all button* */
.SumoSelect .select-all {
	background-color $white
	border-bottom 1px solid $primary
	border-radius 3px 3px 0 0
	cursor pointer
	height 20px
	padding 8px 0 3px 35px
	position relative
}

.SumoSelect .select-all > label, .SumoSelect .select-all > span i {
	cursor pointer
}

.SumoSelect .select-all.partial > span i {
	background-color $primary
}

/* styling for optgroups */
.SumoSelect > .optWrapper > .options li.optGroup {
	padding-left 5px
	text-decoration underline
}
