.timetable {

	.timetable-buttons {
		display flex
		align-items center
		margin 0 auto 30px

		.btn {
			height 38px
			line-height 38px
			background-color $white
			border 1px solid $primary
			color $primary
			transition all .3s ease-in

			&:hover,
			&.selected {
				border-color $primary
				background-color $primary
				color $white
				font-weight normal
			}
		}
	}

	.timetable-wrapper {

		.slick-prev {
			transform translateX(-100%)
			width 50px

			&:before {
				top 0
				bottom auto
				height 50px
				background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 171 306'%3E%3Cpolyline fill='none' stroke='%23000000' stroke-width='36' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='18,288 153,153 18,18 '/%3E%3C/svg%3E%0A")
			}

            &:after {
                content: 'Előző hét'
                display: block;
                width: 100px;
                height: 30px;
                color: #000;
                font-size: 10px;
                position: absolute;
                top: 0;
                right: 5px;
                text-align: right;
            }
		}

		.slick-next {
			transform translateX(100%)
			width 50px

			&:before {
				top 0
				bottom auto
				height 50px
				background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 171 306'%3E%3Cpolyline fill='none' stroke='%23000000' stroke-width='36' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='18,288 153,153 18,18 '/%3E%3C/svg%3E%0A")
			}

            &:after {
                content: 'Következő hét'
                display: block;
                width: 100px;
                height: 30px;
                color: #000;
                font-size: 10px;
                position: absolute;
                top: 0;
                left -10px
            }
		}

		.slick-disabled {
			opacity 0
			cursor default
			pointer-events none
		}

		.day-wrapper {
			position relative
			display inline-block

			.headline,
			.hour {
				position relative
				height 50px
				line-height 18px
				border 1px solid $input-default-border
				border-bottom none
				border-right none
				text-align center
				display flex
				align-items center
				justify-content center

				&:last-child {
					border-bottom 1px solid $input-default-border
				}
			}

			.headline {
				background-color $dark-grey
				color $white
				display flex
				flex-direction column
				justify-content center
				align-items center
			}

			.hour {
				transition all .3s ease-in
				cursor pointer
				font-size 14px

                p {
                    font-size 14px

                    .taken-text {
                        display none

                        small {
                            font-size 12px
                        }
                    }
                }

				&:hover,
				&:focus,
				&.selected {
					background-color $primary
					color $white
				}

				&.no-lawyer {
					background-color $input-default-border
					color $grey
					cursor not-allowed
				}

				&.disabled {
					background-color $input-default-border
					color $grey
				}

				&.disabled:hover {
					background-color $grey
					color $black
				}

				&.taken{
					background-color $secondary-hover
				}

				&.pending{
					background-color #ffc107
				}
			}

			&:nth-child(5n+5),
			&:last-child {

				.headline,
				.hour {
					border-right 1px solid $input-default-border
				}
			}
		}

		for $num in (1..10) {

			&[data-value=loc-{$num}] {

				.day-wrapper {

					.hour:not(.loc-{$num}) {
						background-color $input-default-border
						color $grey
						cursor not-allowed
					}
				}
			}
		}
	}

	.chosen-infos {

		.location,
		.time {
			font-weight bold
		}
	}
}

#bhInfoModal{
	a{
		color $primary
		font-weight bold

		&:hover{
			color $primary
			text-decoration underline
		}
	}
}

.openBhModal{
	color $primary
	font-weight bold

	&:hover{
		color $primary
		text-decoration underline
	}
}

.user-lawyer-select {
    .hour {
        &.disabled {
            .taken-text {
                display: inline !important;
            }
        }
    }
}
