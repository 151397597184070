.house-card {
	display block
	position relative
	margin-bottom 37px
	width 350px
	height 510px
	overflow hidden
	box-shadow $default-box-shadow
	text-align left
	font-size 0

	@media screen and (max-width: 1199px) {
		width 292px
	}

	@media screen and (max-width: 991px) {
		width 325px
	}

	@media screen and (max-width: 768px) {
		box-shadow: -5px 9px 30px 0 rgba(0, 0, 0, 0.15)
		width 237px !important
		height 375px
	}

	&.add-new {
		background-color $light-grey
		box-shadow none
		position relative
		text-align center
		display flex !important
		flex-direction column
		align-items center
		justify-content center
		transition box-shadow .4s ease-in

		h3 {
			color $dark-grey
			font-family $montserrat
			font-weight normal
			margin-bottom 100px
		}

		svg {
			stroke $dark-grey
			width 80px
			height 80px
			margin-bottom 30px
			
			@media screen and (max-width: 768px) {
				margin-bottom 0
				font-size 24px
			}
		}
	}

	.house-thumbnail {
		display block
		width 100%
		height 200px
		background-size cover
		background-position center
		background-repeat no-repeat
		transition background-image 1s ease-in-out
		cursor default
		overflow hidden
		position relative

		@media screen and (max-width: 768px) {
			height 135px
		}

		.slide{
			background-size cover
			width 100%
			height 200px
			background-position center center
			display block

			@media screen and (max-width: 768px) {
				height 135px
			}
		}

		img {
			object-fit cover
			object-position center
			font-family 'object-fit: cover; object-position: center;'
		}

		&.has-slick {

			&:before {
				content ''
				position absolute
				top 0
				left 0
				bottom 0
				right 0
				background linear-gradient(to right, #16161669 0%, transparent 30%, transparent 70%, #16161669 100%)
				z-index 1
				opacity 0
				pointer-events none
				transition opacity 0.3s ease-in-out
			}

			&:hover {

				&:before {
					opacity 1
				}
			}
		}
	}

	.tag {
		position relative
		top 0
		left 0
		transform translateY(-100%)
		z-index 0

		& + .tag {
			left 5px
			background-color $primary
			color $white

			&.not-available {
				background-color $thirdly
			}
		}

		& + a {
			margin-top -25px
			display block
		}
	}

	.address,
	.house-title,
	.size,
	.personal-and-price {
		position relative
		padding-right 20px
		padding-left 20px
		
		@media screen and (max-width: 768px) {
			padding-left 15px
			padding-right 15px
		}

		i {
			display inline-block
			position relative
			background-size contain
			background-repeat no-repeat
			background-position center
		}
	}

	.address {
		font-size 14px
		line-height 15px
		height 50px
		padding 20px 35px 0 45px
		margin-bottom 15px
		overflow hidden
		overflow-wrap break-word
		text-overflow ellipsis
		-webkit-line-clamp 2
		-webkit-box-orient vertical
		display -webkit-box


		&:not(.empty):before {
			content ''
			position absolute
			top 20px
			left 20px
			width 8px
			height 15px
			background-image url('../img/svg/location.svg')
			margin-right 10px

		}
	}

	.house-title {
		font-weight bold
		height 42px
		line-height 21px
		margin-bottom 10px
		overflow hidden
		overflow-wrap break-word
		text-overflow ellipsis
		-webkit-line-clamp 2
		-webkit-box-orient vertical
		display -webkit-box

		@media screen and (max-width: 1199px) and (min-width: 992px) {
			font-size 16px
			line-height 18px
			height 54px
			-webkit-line-clamp 3
			margin-bottom 5px
		}
		
		@media screen and (max-width: 768px) {
			margin-bottom 5px
		}
	}

	.size {
		margin-bottom 15px
		font-size 16px
		font-weight 300
		min-height 72px

		@media screen and (max-width: 1199px) and (min-width: 992px) {
			margin-bottom 5px
		}

		@media screen and (max-width: 768px) {
			margin-bottom 0
			font-size 12px
			min-height 58px
		}

		.area {
			position relative
			width 85px

			@media screen and (max-width: 768px) {
				width 71px
			}
		}
	}

	.personal-and-price {
		text-align right
		font-weight bold
		font-size 18px
		display flex
		align-items center
		padding-left 10px

		@media screen and (max-width: 768px) {
			font-size 12px
		}
		
		.star,
		.compare,
		.hide {
			padding 10px
			background-position center
			background-repeat no-repeat
			cursor pointer
		}

		.star {
			width 38px
			height 37.16px
			background-image url('../img/svg/star.svg')
			background-size 18px 17.16px

			&:hover {
				background-image url('../img/svg/star-hover.svg')
			}

			&.active {
				background-image url('../img/svg/star-active.svg')
			}
		}

		.compare {
			width 37px
			height 33.39px
			background-image url('../img/svg/compare.svg')
			background-size 17px 13.39px

			&:hover {
				background-image url('../img/svg/compare-hover.svg')
			}

			&.active {
				background-image url('../img/svg/compare-active.svg')
			}
		}

		.hide {
			width 36px
			height 33.47px
			background-image url('../img/svg/hide.svg')
			background-size 16px 13.47px
			margin-right auto

			&:hover {
				background-image url('../img/svg/hide-hover.svg')
			}

			&.active {
				background-image url('../img/svg/hide-active.svg')
			}
		}
	}

	.bottom-section {
		background-color $light-grey
		display block
		align-items center
		position absolute
		height 60px
		bottom 0
		left 0
		right 0

		@media screen and (max-width: 768px) {
			height 40px
		}

		.profile-img {
			z-index 5
			position absolute
			top 50%
			left 20px
			transform translateY(-50%)
			width 30px
			height 30px
			background-size cover
			background-position center
			background-repeat no-repeat
			text-align center
			font-family $montserrat
			font-weight 300
			font-size 14px
			line-height 30px
			border-radius 30px			

			@media screen and (max-width: 768px) {
				left 14px
				width 20px
				height 20px
				font-size 9px
				line-height 20px
			}

			&.has-img {
				color transparent
			}

			&:not(.has-img) {
				border 1px solid black
			}
			
		}

		.seller-name {
			padding 15px 50px 15px 60px
			line-height 15px
			display flex
			align-items center
			position relative
			height 60px

			@media screen and (max-width: 768px) {
				padding 10px 50px 10px 40px
				line-height 10px
				font-size 9px
				height 40px
			}
		}
		
		.upload-time {
			position absolute
			top 50%
			right 20px
			transform translateY(-50%)

			@media screen and (max-width: 768px) {
				font-size 9px
				right 13px
			}
		}
	}
}