.lawyer-option {

	.lawyers-wrapper {
		display flex
		justify-content center

		@media screen and (max-width: 575px) {
			flex-wrap wrap
			justify-content space-around
		}

		.lawyer-card {
			transition color .15s ease-in
			margin-left 15px
			margin-right 15px
			margin-bottom 20px

			img {
				transition opacity .15s ease-in
			}

			&:hover {
				color $primary
				cursor pointer
			}
		}

		&.active {

			.lawyer-card {
				color $grey

				img {
					opacity 0.5
				}

				&.active {
					color $primary

					img {
						opacity 1
					}
				}
			}
		}
	}

	.lawyers-map {

		.g-map {
			width 540px
			height 202px
			margin-left auto
			margin-right auto

			@media screen and (max-width: 991px) {
				width 100%
				height 300px
			}

			@media screen and (max-width: 768px) {
				height 200px
			}
		}
	}
}