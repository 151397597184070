.deal-tabs{
  li{
    padding-right 5px

    .nav-link{
      background-color #ebebeb
      color: #959595
      border: none
      padding: 0px 25px
      width 100%
      margin-top 15px
      height auto
      line-height 40px

      @media screen and (max-width: 767px) {
          padding 0px 10px
          font-size 14px
      }

      &.active{
        background-color $primary
        color $white
        margin-top 10px
        height: 45px;
        line-height: 45px;
        border none
      }
    }
  }
}

.mydeals-table{
  border: none;
  box-shadow 0 10px 30px 0px rgba($black,.15)
  thead{
    background-color $primary
    border none

    tr{
      border: none
      th{
        color $white
        border: none
        font-weight bold
        white-space nowrap
      }
    }
  }

  tbody{
    tr{
      td{
        border none
        font-weight bold

        &.border-bottom{
          border-bottom: 1px solid #cccccc
        }

        &.w-20{
          width 20px
        }

        a{
          color $primary
          font-weight 300
          position relative

          &.next-step{
            svg{
              stroke $secondary
              font-weight 400
            }

            color #010101
            font-weight 300
          }

          &.underline {
            text-decortaion underline
          }

          // &.underline::after{
          //   content ""
          //   width 100%
          //   height 1px
          //   background-color: $primary
          //   position absolute
          //   bottom 0
          //   left 0
          // }
        }


      }
    }
  }
}
