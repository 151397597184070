// DISPLAY FLEX MIXIN
dflex($direction, $align, $justify) {
    display flex
    flex-direction $direction
    align-items $align
    justify-content $justify
}

dark-bg() {

    .form-control {
        background-color $dark-grey
        color $white

        &.range-box {

            label {
                color $white
            }

            &.focus {

                input {
                    background-color $dark-grey
                    color $white
                }

                input,
                span {
                    color $white
                }
            }
        }
    }

    .SumoSelect>.CaptionCont>span {
        color $white
    }

    .SumoSelect.open>.optWrapper {
        background-color $dark-grey
        box-shadow 0 10px 30px rgba(255, 255, 255, 0.15)
    }

    .SumoSelect>.optWrapper>.options li.opt {
        background-color $dark-grey
    }

    .SumoSelect .select-all>label,
    .SumoSelect>.CaptionCont,
    .SumoSelect>.optWrapper>.options li.opt label {
        color $white
    }

    .SumoSelect>.optWrapper>.options li.opt.selected label {
        color $primary
    }

    .clear-button {
        background-image url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fal' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10 fa-2x'%3E%3Cpath fill='%23ffffff' d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z' class=''%3E%3C/path%3E%3C/svg%3E")
        width 13px
    }
}

singe-line-ellipsis() {
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
}

.singe-line-ellipsis {
    singe-line-ellipsis()
}
