.house-list {

	.house-cards-wrapper {
		display flex
		flex-wrap wrap
		position relative
		
		@media screen and (max-width: 575px) {
			text-align center
		}

		.house-card {
			display inline-block

			&:nth-child(3n+1),
			&:nth-child(3n+2) {
				margin-right 15px
			}

			@media screen and (max-width: 991px) {

				&:nth-child(3n+1),
				&:nth-child(3n+2) {
					margin-right inherit
				}

				&:nth-child(2n+1) {
					margin-right 15px
				}
			}

			@media screen and (max-width: 575px) {
				margin-left auto !important
				margin-right auto !important
			}
		}
	}
}