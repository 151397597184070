.why-ketzo {
	background-image url('../img/why-ketzo-bg.jpg')
	background-size cover
	background-position bottom center
	background-repeat no-repeat
	box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15)

	.tab-mobile-title {

		@media screen and (min-width: 992px) {
			display none
		}
	}

	.tab-content {

		@media screen and (max-width: 991px) {

			& > .tab-pane {
				display block
			}
			.fade:not(.show) {
				opacity 1
			}
		}
	}

	.collapse {

		@media screen and (min-width: 992px) {
			display block
		}
	}

	.tab-text {

		p {
			position relative
			min-height 150px
			margin-bottom 20px

			@media screen and (max-width: 991px) {
				height auto
				margin-bottom 30px
			}
		}

		.btn {

			@media screen and (max-width: 991px) {
				display block
				width 100%
			}
		}
	}
}