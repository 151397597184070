.dream-houses {

	.house-cards-wrapper {
		display flex
		justify-content space-between

		@media screen and (max-width: 575px) {
			width calc(100% + 30px)
			margin-left: -15px
		}

		.slick-track {

			@media screen and (max-width: 450px) {
				margin-left: -30px
			}
		}

		.house-card:nth-child(2) {

			@media screen and (max-width: 1199px) {
				margin 0 20px 50px
			}

			@media screen and (max-width: 991px) {
				margin 0 15px 25px
			}
		}
	}

	.btn {
		margin-top 10px
		padding 0 20px
	}
}