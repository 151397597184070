/**
 * Slick theme change here
 */
.slick-loading .slick-list {
	background $white url('../img/ajax-loader.gif') center center no-repeat
}

/* Icons */
@font-face {
	font-family 'slick'
	font-weight normal
	font-style normal
	src url('../fonts/slick/slick.eot')
	src url('../fonts/slick/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick/slick.woff') format('woff'), url('../fonts/slick/slick.ttf') format('truetype'), url('../fonts/slick/slick.svg#slick') format('svg')
}
/* Arrows */
.slick-prev, .slick-next {
	font-size 0
	line-height 0
	position absolute
	top 50%
	display block
	width 20px
	height 20px
	padding 0
	transform translate(0, -50%)
	cursor pointer
	color transparent
	border 0
	outline 0
	background transparent

	&:hover, &:focus {
		color transparent
		outline 0
		background transparent

		&:before {
			opacity 1
		}
	}

	&.slick-disabled:before {
		opacity .25
	}

	&:before {
		font-family 'slick'
		font-size 20px
		line-height 1
		opacity .75
		color $white
		-webkit-font-smoothing antialiased
		-moz-osx-font-smoothing grayscale
	}
}
.slick-prev {
	left -25px

	&:before {
		content '←'
	}
}

[dir='rtl'] {
	.slick-prev {
		right -25px
		left auto

		&:before {
			content '→'
		}
	}
}

.slick-next {
	right -25px

	&:before {
		content '→'
	}
}
[dir='rtl'] {
	.slick-next {
		right auto
		left -25px

		&:before {
			content '←'
		}
	}
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom 30px
}

.slick-dots {
	position absolute
	bottom -10px
	display block
	width 100%
	padding 0
	margin 0
	list-style none
	text-align center
	line-height 0.1px

	li {
		position relative
		display inline-block
		width 9px
		height 9px
		margin 0 5px
		padding 0
		cursor pointer

		button {
			font-size 0
			line-height 0
			display block
			width 9px
			height 9px
			padding 0
			cursor pointer
			color transparent
			outline 0
			background transparent
			border 1px solid $grey
			border-radius 50%

			&:hover,
			&:focus {
				outline 0
			}
		}

		&.slick-active button {
			border-color $primary
			background-color $primary
		}
	}
}

// prevent slide flickering when going from last to first slide
.slick-track, .slick-list {
	perspective 2000px
	backface-visibility hidden
}