.toast-title {
	font-weight bold
}

.toast-message {
	word-wrap break-word
	padding-right 30px

	a {
		text-decoration underline

		&:hover {
			text-decoration underline
		}
	}
}

.toast-close-button {
	position static
	right -0.3em
	top -0.3em
	float right
	font-size 20px
	font-weight bold
	color #FFFFFF
	text-shadow none
	line-height 1

	svg {
		stroke $white
		fill none
		transition fill 0.4s ease-out

		line {
			stroke $white
			transition all 0.4s ease-out
		}
	}

	&:hover {

		svg {
			stroke transparent
			fill $white

			line {
				stroke $dark-grey
			}
		}
	}
}

.toast-close-button:hover,
.toast-close-button:focus {
	color $black
	text-decoration none
	cursor pointer
}

button.toast-close-button {
	padding 0
	cursor pointer
	background transparent
	border 0
	-webkit-appearance none
}

.toast-top-center {
	top 28px
	right 0
	width 100%
	display flex
	flex-direction column-reverse
}

.toast-bottom-center {
	bottom 0
	right 0
	width 100%
}

.toast-top-full-width {
	top 0
	right 0
	width 100%
}

.toast-bottom-full-width {
	bottom 0
	right 0
	width 100%
}

.toast-top-left {
	top 12px
	left 12px
}

.toast-top-right {
	top 12px
	right 12px
}

.toast-bottom-right {
	right 12px
	bottom 12px
}

.toast-bottom-left {
	bottom 12px
	left 12px
}

#toast-container {
	position fixed
	z-index 999999
	pointer-events none
}

#toast-container * {
	box-sizing border-box
}

#toast-container > div {
	position relative
	pointer-events auto
	overflow hidden
	margin 0 0 6px
	padding 15px 15px 15px 50px
	width 300px
	border-radius 0
	background-position 15px center
	background-repeat no-repeat
	box-shadow $default-box-shadow
	color $white
}

#toast-container > div:hover {
	cursor pointer
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
	width 300px
	margin-left auto
	margin-right auto
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
	width 96%
	margin-left auto
	margin-right auto
}

.toast {
	background-color $dark-grey
}

.toast-success {
	background-color $secondary-hover

	.toast-close-button:hover svg line {
		stroke $secondary-hover
	}
}

.toast-error {
	background-color $error-color

	.toast-close-button:hover svg line {
		stroke $error-color
	}
}

.toast-info {
	background-color $primary

	.toast-close-button:hover svg line {
		stroke $primary
	}
}

.toast-warning {
	background-color #F89406

	.toast-close-button:hover svg line {
		stroke #F89406
	}
}

.toast-progress {
	position absolute
	left 0
	bottom 0
	height 4px
	background-color $black
}

@media screen and (max-width: 768px) {

	#toast-container > div {
		padding 15px
		width 25em
	}
}

@media screen and (max-width: 575px) {

	#toast-container > div {
		padding 8px
		width 18em
	}

	#toast-container .toast-close-button {
		right -0.2em
		top -0.2em
	}
}

#toast-container>.toast-error{
    background: $error-color !important
}
