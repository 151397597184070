.page-404 {
	background-color $primary
	color $white
	height 100vh
	display flex
	flex-direction column
	align-items center
	justify-content center
	padding-top 76px

	@media screen and (max-width: 991px) {
		padding-top 70px
	}
	
	h1 {
		font-size 200px
		margin-bottom 50px

		@media screen and (max-width: 575px) {
			font-size 100px
		}
	}

	a {
		text-decoration underline
		color $secondary
	}
}