.upload-wrapper {
	display none

	&:first-child {
		display block
	}

	&.has-img + .upload-wrapper {
		display block
	}

	.delete-upload{
		cursor pointer
		position absolute
		top 10px
		right 20px
		width 16px
		height 16px
		background-image url('../img/svg/close-primary.svg')
		background-size contain
		background-position center
		background-repeat no-repeat
		z-index 10
	}

	.upload-box {
		position relative
		padding-top 75%
		background-color $light-grey
		text-align center
		background-size cover
		background-position center
		background-repeat no-repeat
		margin-bottom 15px
		cursor pointer

		.upload-box-content {
			display flex
			flex-direction column
			align-items center
			justify-content center
			position absolute
			top 0
			left 10%
			bottom 0
			right 10%

			svg {
				stroke $dark-grey
				width 65px
				height 65px
			}

			h3 {
				font-weight 300
				color $dark-grey
				margin-top 20px

				&.fs-smaller {
					font-size 19px
				}
			}
		}
	}

	 &.has-img .upload-box .upload-box-content {
		 display none
	 }

     & .upload-box .overlay{
        width: 100%
        height: 100%
        background: rgba(0, 0, 0, 0.2);
        position absolute
        top 0
        left 0

        svg {
            width 40px;
            height 40px;
            position absolute;
            top: 50%;
            left: 50%;
            margin: -20px 0 0 -20px;

            & polygon, & line, & polyline{
                stroke: #fff
            }
        }     
    }
}

#rotate-image-modal{
    .modal-body{
        margin 0
        padding 0
        overflow hidden
    }
    .left{
        position absolute
        left 10px
        top 50%
        width 40px
        height 40px
        margin-top -20px
        background rgba(255, 255, 255, 0.7)
        padding 10px
        cursor pointer
        z-index 10
    }

    .right{
        position absolute
        right 10px
        top 50%
        width 40px
        height 40px
        margin-top -20px
        transform: scaleX(-1);
        background rgba(255, 255, 255, 0.7)
        padding 10px
        cursor pointer
        z-index 10
    }

    img.img-container{
        margin 0
        padding 0
    }
}