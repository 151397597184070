.pdf-options {
	display flex
	align-items baseline
	justify-content flex-end

	.svg-wrapper {
		display block
		position relative
		width 22px
		height 22px
		cursor pointer

		&:not(:last-child) {
			margin-right 20px
		}

		svg {
			stroke $primary
		}

		&:hover {

			svg {
				stroke $primary-hover
			}
		}
	}
}