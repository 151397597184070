.modal {

	.modal-dialog {

		.modal-content {
			border-radius 0
			border none

			.modal-header {
				padding 15px 0
				border none
				border-radius 0

				.modal-title {
					display flex
					flex-wrap wrap
					padding-right 50px
					padding-left: 15px;
				}

				svg {
					margin-right 10px
				}

				.close {
					transform translateX(-100%)

					svg {
						margin-right 0
						stroke $primary
						transition all .3s
					}

					&:hover {

						svg {
							stroke $primary-hover
						}
					}
				}
			}

			.modal-body {
				padding 15px

				.d-flex {

					.btn {
						flex 1

                        @media screen and (max-width: 320px) {
                            font-size 16px
                        }
					}
				}
			}

			.modal-footer {
				margin-left 5px
				margin-right 5px
				border none
			}
		}
	}

	&.tipp {

		.modal-content {

			.modal-header {
				background-color $dark-grey
				color $white
			}
		}
	}
	
	&#profile-picture {
		
		.modal-body {
			padding 0
		}

		.modal-content {
			background-color transparent
		}

	}
	
}
