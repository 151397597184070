.property-deals {

	.property-new-messages {
		display block
		position relative
		cursor pointer
		padding-left 32px
		font-weight bold
		color $primary
		transition color .25s ease-in

		&:before {
			content ''
			position absolute
			top 50%
			left 0
			height 22px
			width 22px
			transform translateY(-50%)
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 360'%3E%3Crect x='9' y='135' fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' width='342' height='216'/%3E%3Cpolyline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='351,135 180,261 9,135 '/%3E%3Cpolyline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='145.8,235.8 9,351 351,351 214.2,235.8 '/%3E%3Cline fill='none' stroke='%23008ccd' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='180' y1='9' x2='180' y2='63'/%3E%3Ccircle fill='%23008ccd' cx='180' cy='99' r='9'/%3E%3C/svg%3E%0A")
		}

		&:hover,
		&:focus {
			color $primary-hover

			&:before {
				background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 360'%3E%3Crect x='9' y='135' fill='none' stroke='%2328a0c8' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' width='342' height='216'/%3E%3Cpolyline fill='none' stroke='%2328a0c8' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='351,135 180,261 9,135 '/%3E%3Cpolyline fill='none' stroke='%2328a0c8' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='145.8,235.8 9,351 351,351 214.2,235.8 '/%3E%3Cline fill='none' stroke='%2328a0c8' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='180' y1='9' x2='180' y2='63'/%3E%3Ccircle fill='%2328a0c8' cx='180' cy='99' r='9'/%3E%3C/svg%3E%0A")
			}
		}
	}

	table {

		tbody {

			tr {

				td {
					text-align left
					padding 13px 20px
					border-top none
					//border-bottom 1px solid $input-default-border
					white-space nowrap

					&:last-child {
						text-align right
					}
				}

				&:not(:first-child) {

					td {
						color $grey
					}
				}

				&.new-deal {

					td {
						font-weight bold
						color $black

						a {
							cursor pointer
							color $primary
							transition color .25s ease-in

							&:hover,
							&:focus {
								color $primary-hover
							}
						}
					}
				}
			}
		}
	}
}

#dealModal .modal-dialog{
	max-width 75%

	@media screen and (max-width: 991px) {
		max-width 95%
	}

	.modal-content .modal-body {

		@media screen and (max-width: 991px) {
			padding 5px
		}

		.pdf-wrapper {

			@media screen and (max-width: 991px) {
				padding 0
			}

			.pdf-content {
				padding 25px 15px

				@media screen and (max-width: 991px) {
					padding 30px 10px
				}
			}
		}
	}
}

.bootbox-confirm{
	.bootbox-close-button{
		position: absolute;
		right: -1rem;
	}
}
