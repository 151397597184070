.user-photo{
  position relative

  .delete-upload{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    height: 16px;
    background-image: url(../img/svg/close-primary.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
  }
}

.my-deals{
  a.link{
    color $primary
    font-weight bold

    &:hover{
      text-decoration underline
    }
  }
}

