.flow {

	.section-title {

		@media screen and (max-width: 1199px) and (min-width: 992px) {
			font-size 30px
		}
	}

	.flow-wrapper {
		position relative

		&:before {
			content ''
			position absolute
			top 40px
			bottom 40px
			border-right 2px dashed #CCCCCC

			@media screen and (max-width: 991px) {
				display none
			}
		}

		.flow-item {
			display flex
			align-items center
			position relative

			img {
				height 80px
				width 80px

				@media screen and (max-width: 991px) {
					height 120px
					width 120px
					margin-bottom 30px
				}

				@media screen and (max-width: 768px) {
					height 60px
					width 60px
					margin-bottom 20px
				}
			}

			p {

				@media screen and (max-width: 1199px) and (min-width: 992px) {
					font-size 14px
				}

				@media screen and (max-width: 768px) {
					max-width 220px !important
				}
			}

			&:before {
				content ''
				position absolute
				top 50%
				height 8px
				width 8px
				background-color $primary
				border-radius 50%
				
				@media screen and (max-width: 991px) {
					display none
				}
			}

			&:after {
				content ''
				position absolute
				top 40px
				width 30px
				border-top 2px dashed #CCCCCC

				@media screen and (max-width: 991px) {
					display none
				}
			}

			&:not(:last-child) {
				margin-bottom 30px
				
				@media screen and (max-width: 991px) {
					margin-bottom 50px
				}
					
				@media screen and (max-width: 768px) {
					margin-bottom 35px
				}
			}
			
			&:last-child {
				
				@media screen and (max-width: 991px) {
					margin-bottom 50px
				}
			}

			&:nth-child(1) {

				&:before {
					background-color $primary
				}

				p {

					@media screen and (max-width: 991px) {
						max-width 280px
					}
				}
			}

			&:nth-child(2) {

				&:before {
					background-color #289FC7
				}

				p {
					max-width 245px
				}
			}

			&:nth-child(3) {

				&:before {
					background-color #5AB4AA
				}

				p {
					max-width 185px

					@media screen and (max-width: 991px) {
						max-width 280px
					}
				}
			}

			&:nth-child(4) {

				&:before {
					background-color #8BC750
				}

				p,
				img {
					opacity 0
					pointer-events none

					@media screen and (max-width: 991px) {
						opacity 1
						pointer-events auto
					}
				}

				p {

					@media screen and (max-width: 991px) {
						max-width 280px
					}
				}
			}

			&:nth-child(5) {

				&:before {
					background-color #BDDC1E
				}
			}
		}

		&.left-side {

			&:before {
				right -60px
			}

			.flow-item {
				justify-content flex-end

				@media screen and (max-width: 991px) {
					flex-direction column-reverse
					justify-content center
				}

				p {
					text-align right
					margin-right 15px

					@media screen and (max-width: 991px) {
						text-align center
						margin-right 0
					}
				}

				&:before {
					right -30px
					transform translate(calc(50% - 8px), -50%)
				}

				&:after {
					right -60px
					transform translateY(-50%)
				}

				&:nth-child(4) {

					&:before {
						right -90px
						transform translate(calc(50% + 8px), -50%)
					}

					&:after {
						right -90px
					}
				}

				&:nth-child(5) {

					p {
						max-width 110px

						@media screen and (max-width: 991px) {
							max-width none
						}
					}
				}
			}
		}

		&.right-side {

			&:before {
				left -60px
			}

			.flow-item {
				justify-content flex-start

				@media screen and (max-width: 991px) {
					flex-direction column
					justify-content center
				}

				p {
					text-align left
					margin-left 15px

					@media screen and (max-width: 991px) {
						text-align center
						margin-left 0
					}
				}

				&:before {
					left -30px
					transform translate(calc(-50% + 8px), -50%)
				}

				&:after {
					left -60px
					transform translateY(-50%)
				}

				&:nth-child(4) {

					&:before {
						left -90px
						transform translate(calc(-50% - 8px), -50%)
					}

					&:after {
						left -90px
					}
				}

				&:nth-child(5) {

					p {
						max-width 150px
					}
				}
			}
		}
	}

	.extra-flow-item {
		position relative
		top calc(4 * 80px)
		text-align center

		img {
			height 80px
			width 82px
			margin-bottom 20px
		}

		p {
			
			@media screen and (max-width: 1199px) and (min-width: 992px) {
				font-size 14px
			}
		}
	}
}