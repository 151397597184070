.property-compare {
	margin-bottom 100px

	@media screen and (max-width: 991px) {
		margin-bottom 50px
	}

	.g-map {
		width 100%
		height 380px

		@media screen and (max-width: 991px) {
			height 300px
		}

		@media screen and (max-width: 768px) {
			height 200px
		}
	}

	.table-container {

		@media screen and (max-width: 991px) {
			max-width none
		}
	}

	.row-for-table {
		margin-left -30px
		margin-right -30px

		@media screen and (max-width: 1010px) {
			margin-left: -22px
			margin-right: -22px
		}

		@media screen and (max-width: 991px) {
			margin-left: -30px
			margin-right: -15px
		}
	}

	.property-img {
		position relative
		padding-top 67%
		background-size cover
		background-position center
		background-repeat no-repeat

		.remove-prop-from-compare {
			cursor pointer
			position absolute
			top 10px
			right 10px
			width 16px
			height 16px
			background-image url('../img/svg/close-primary.svg')
			background-size contain
			background-position center
			background-repeat no-repeat
		}
	}

	table {
		border-spacing 30px 0
		border-collapse separate

		@media screen and (max-width: 991px) {
			display block
			width 100%
			overflow-x auto
		}

		thead {

			tr {

				th {
					width 25%
					border none
					padding 20px 0 7px
					vertical-align top
					min-width 200px

					&:first-child {
						padding-left 15px
					}
				}

				td {
					border none
					padding 7px 0
					vertical-align middle
				}

				&:nth-child(2) {

					td {
						vertical-align top
						font-weight bold
					}
				}
				
				&:nth-child(4) {
					
					td {
						padding-bottom 20px
					}
				}
			}
		}

		tbody {

			tr {

				td {
					padding 14px 30px
					border none
					position relative
					text-align left

					&:after {
						content ''
						position absolute
						bottom 0
						left 15px
						right 15px
						height 1px
						background-color $white
					}

					&:first-child {
						padding-left 15px
						text-align left

						&:after {
							left 0
							right 0
							background-color $input-default-border
						}
					}

					&:nth-child(2) {
						background-color $primary-hover
					}

					&:nth-child(3) {
						background-color $thirdly
					}

					&:nth-child(4) {
						background-color $secondary-hover
					}

					.tick {
						position relative
						width 10px
						height 10px
						background-image url('../img/tick.png')
						background-size contain
						background-position center
						background-repeat no-repeat
					}
				}

				&:first-child {

					td {
						white-space nowrap

						&:first-child:before {
							content ''
							position absolute
							top 0
							left 0
							right 0
							height 1px
							background-color $input-default-border
						}
					}
				}

				&:last-child {

					td:not(:first-child):after {
						content none
					}
				}
			}
		}
	}
}