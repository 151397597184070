.static {
	color $dark-grey

	.section-title {
		margin-bottom 25px
	}

	ul {
		list-style disc
	}

	h3 {
		position relative
		padding 25px 0 25px 22px
		margin 25px 0
		display block

		&.partners-title {
			font-size 16px
			font-weight 300
			padding 0 0 0 22px
			margin 0

			@media screen and (max-width: 768px) {
				padding 0 0 0 22px
				margin 0 0 15px
			}
		}

		@media screen and (max-width: 768px) {
			margin 15px 0
			padding 15px 0 15px 22px
		}

		&:before {
			content ''
			position absolute
			top 35px
			left 0
			width 12px
			height 10px
			background-image url('../img/svg/tab-icon.svg')
			background-position center
			background-size contain
			background-repeat no-repeat

			@media screen and (max-width: 768px) {
				top 22px
			}
		}

		&.partners-title:before {
			top 5px

			@media screen and (max-width: 768px) {
				top 5px
			}
		}

		&:not(.partners-title):after {
			content ''
			position absolute
			bottom 0
			left 0
			right 0
			height 1px
			background-color $primary
		}
	}

	p {
		a {
			color $primary
			font-weight bold

			&:hover, &:focus {
				color $primary
			}
		}
	}

	ul {
		margin-left 50px
		margin-top 20px

		li:not(:last-child) {
			margin-bottom 10px
		}
	}
}

.template__static {
	table {
		border-collapse collapse !important
		border-spacing 0
		clear both
		max-width none !important
		width 100% !important
		border none !important
		height auto !important
		margin-top 15px
		margin-bottom 15px
		display inline-block
		max-width 100%
		overflow-x auto
		vertical-align top
		white-space nowrap
		cursor grab
	}

	table tr {
		height auto !important
	}

	table tr:nth-of-type(odd) {
		background #f7f7f7 !important
	}

	table td, table th {
		border 1px solid #008ccd !important
		padding 12px !important
		width 100%
		height 100% !important
		white-space normal

		@media all and (max-width: 991px) {
			min-width 250px
		}
	}

	table td {
		text-align left

		@media all and (max-width: 991px) {
			text-align left !important
		}
	}

	table th {
		background #008ccd !important
		color #fff
		font-weight 700
		width 100% !important
	}
}