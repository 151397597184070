.own-select {
	position: relative;
	width: 100%;

	img {
		z-index: 1;

		&.opened {
			z-index: 100000;
			transform: rotate(180deg);
		}

	}

	select {
		opacity: 0.25;
		height: 40px!important;
		line-height: 40px!important;
		border-radius: 0!important;
	}

	@media screen and (max-width: 576px) {
		display: block;
		max-width: none;
	}

	.select {
		display: block;
		text-align: left;
		color: $black;
		transition: ease .2s;
		border-radius: 3px;
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		width: 100%;
	    position: relative;
	    background: transparent;
        border: 1px solid $inputborder;
        border-radius: 4px;

		&.disabled {
			pointer-events: none;
			opacity: 0.75;

			.arrow {
				display: none;
			}


		}

		&.hover {

		    .label {
		    	opacity: 0.5;
		    }

            .arrow {
                transform: translate(0,-60%) rotate(180deg);
            }

		}

		&:hover {
            cursor: pointer;
			border-color: $primary;
		}

		.label {
			position: absolute;
			top: 50%;
			left: .7rem;
			transform: translateY(-50%);
			line-height: 20px;

			p {
				display: none;
			}

		}

		.arrow {
			position: absolute;
			top: 50%;
			right: .5rem;
			transform: translateY(-55%);
            font-size: 13px;
            margin-top: 3px;
            color: $black;
            z-index: 10;
            transition: ease .2s;
		}

	}

	.options {
		display: none;
		text-align: left;
		color: $black;
		font-size: 14px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
		width: 100%;
		padding: 0;
	    max-height: 420px;
	    overflow-y: scroll;
        border-radius: 4px;
        border 1px solid $inputborder
        box-shadow: 0 0 6px 0 rgba($black,.1);

		a {
			display block
			color: $black;
			font-size: 14px;

			&:hover {
				text-decoration none
			}

		}

		&::-webkit-scrollbar {
		    width: 0;
		}


		&::-webkit-scrollbar-track {
		    width: 0;
		    height: 0;
		    -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0);
		}


		&::-webkit-scrollbar-thumb {
			width: 0;
			height: 0;
			background-color: transparent;
			outline: none;
		}

		li {
			background: $white;
			list-style: none;
			transition: ease .2s;
			line-height: 40px;
			cursor: pointer;
			padding: 0 calc(1rem - 4px);
			white-space: nowrap;

			&:hover {
				background: $grey;
			}

			&.disabled {
				pointer-events: none;
			}

		}

	}

	&.with-picture {

		.options {

			li {
				dflex(row, center, flex-start)
				font-family $bold
				line-height 1
				padding 1.25rem 1.9rem calc(.75rem + 1px) 1.9rem
				position relative

				div {
					white-space normal
				}

				&:after {
					content ''
					position absolute
					top calc(100% - 1px)
					left 1.9rem
					height 1px
					width calc(100% - 3.8rem)
					transition ease .2s
				}

				small {
					font-family $light
					margin-bottom 0
				}

				img {
					width 28px
					margin-right .75rem
					margin-top -3px
					margin-bottom 3px
				}

				&:hover {
					background $primary

					&:after {
						background $primary
					}

				}

				&:last-of-type {

					&:after {
						content none
					}

				}

			}

		}


	}

}
