/**
 * Custom button styles
 */
input[type='submit'], input[type='reset'], input[type='button'], .btn {
    background-color $primary
    border none
    border-radius 0
    box-shadow none
    color $white
    cursor pointer
    display inline-block
    font-family $montserrat
    font-size 18px
    font-weight normal
    height auto
    line-height normal
    padding 11.5px 30px
    margin-bottom 20px
    width auto
    outline none
    position relative
    text-align center
    text-shadow none
    appearance none
    transition background-color .2s ease-out, border-color .2s ease-out, color .2s ease-out
    white-space normal

    &.disabled{
        padding-left 10px
    }

    svg{
        height: 25px;
        margin-right: 5px;
        vertical-align: middle;

        path{
            fill: #fff;
        }
    }

    @media screen and (max-width: 991px) {
        padding 11.5px 20px
    }

    &.disabled {
        cursor not-allowed !important
        background-color $grey !important
        color $white !important
    }

    &:hover, &:focus, &:active {
        box-shadow none
        color $white
    }

    &:hover {
        background-color $primary-hover
    }

    &.smaller-on-mobile {
        @media screen and (max-width: 991px) {
            font-size 16px
        }
    }
}

.btn-secondary {
    color $black
    background-color $secondary

    &:hover, &:focus, &:active {
        color $black
    }

    &:hover {
        background-color $secondary-hover
    }
}

.btn-facebook {
    background-color $facebook
}

.btn-secondary-outline {
    color $secondary
    background-color transparent
    border 2px solid $secondary
    padding 9.5px 30px

    &:focus, &:active {
        color $secondary
    }

    &:hover {
        color $secondary-hover
        background-color transparent
        border 2px solid $secondary-hover
    }
}

.btn-default {
    color $primary
    background-color transparent
    border 2px solid $primary
    padding 9.5px 28px

    &:focus, &:active {
        color $white
        background-color $primary
    }

    &:hover {
        color $white
        background-color $primary
    }
}

.btn-danger {
    color $white
    background-color $error-light-color

    &:focus, &:active {
        color $white
        background-color $error-color
    }

    &:hover {
        color $white
        background-color $error-color
    }
}

.btn-tip {
    font-size 14px
    margin-bottom 0
}

.btn-fluid {
    display block
    width 100%
    margin-bottom 0
}

input[type='submit'], input[type='reset'], input[type='button'] {
    overflow hidden
}

.text-btn {
    color $primary
    background-color $white
    margin-right 20px

    @media screen and (max-width: 575px) {
        margin-right 0
        margin-bottom 0
    }

    &:hover, &:focus, &:active {
        box-shadow none
        color $primary-hover
    }

    &:hover {
        background-color $white
    }
}

.add-new-btn {
    .plus-icon {
        position relative
        display inline-block
        width 30px
        height 30px
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 360'%3E%3Cline fill='none' stroke='%23ffffff' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='180' y1='288' x2='180' y2='72'/%3E%3Cline fill='none' stroke='%23ffffff' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='72' y1='180' x2='288' y2='180'/%3E%3Crect x='9' y='9' fill='none' stroke='%23ffffff' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' width='342' height='342'/%3E%3C/svg%3E")
        background-size contain
        background-position center
        background-repeat no-repeat
        vertical-align middle
        margin-right 8px
    }
    span{
        vertical-align middle
    }
}

.remove-btn {
    cursor pointer

    .remove-icon {
        display block
        position relative
        width 22px
        height 22px
        margin-top 23px
        margin-left 15px
        background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 360'%3E%3Ccircle fill='none' stroke='%23959595' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' cx='180' cy='180' r='171'/%3E%3Cline fill='none' stroke='%23959595' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='117' y1='243' x2='243' y2='117'/%3E%3Cline fill='none' stroke='%23959595' stroke-width='18' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='117' y1='117' x2='243' y2='243'/%3E%3C/svg%3E%0A")
        background-size contain
        background-position center
        background-repeat no-repeat
    }
}


