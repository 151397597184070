.dream-houses {

	.btn {
		margin-top 10px
		padding 0 20px

		@media screen and (max-width: 400px) {
			font-size 15px
			padding 0
		}
	}
}