.tip-card {
	box-shadow $default-box-shadow
	margin-bottom 30px

	.tip-header {
		background-color $dark-grey
		color $white
		font-family $montserrat
		font-weight normal
		font-size 18px
		padding 15px 20px
		display flex
		align-items center

		svg {
			width 22px
			height 22px
			margin-right 10px
		}
	}

	.tip-body {
		background-color $white
		font-family $montserrat
		font-size 14px
		line-height 1.3em
		padding 20px

		p, span, li{
			font-size 14px
		}
		
		.description-title {
			font-weight bold
			margin-bottom 20px
		}

		.description-body {
			overflow-wrap break-word
			word-wrap break-word
			word-break break-word

			ul {
				list-style initial
				margin-left 17px
				margin-top 15px
			}
		}
	}
}